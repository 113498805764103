import * as _ from 'lodash';
import {Component, OnInit, ViewChild} from '@angular/core';
import {SharedDataService} from '../../../../services/shared-data.service';
import {forkJoin, Subscription} from 'rxjs';
import {AutoUnsubscribe} from '../../../../decorators/auto-unsubscribe';
import {AlertService} from '../../../../services/alert.service';
import {SpreadingTemplateService} from '../../../../services/spreading-template.service';
import {NgxPopupService} from '../../../shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import {NgxPopupComponent} from '../../../shared/ngx-popups/ngx-popups/components/popup.component';
import {KEYS, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode} from '@circlon/angular-tree-component';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateGenericEquationComponent} from '../create-generic-equation/create-generic-equation.component';
import {CreateSubItemComponent} from '../create-sub-item/create-sub-item.component';
import {ImportExportTemplateComponent} from '../import-export-template/import-export-template.component';
import {DownloadService} from '@services/download.service';
import {
  TAXONOMY_ITEM_IDS,
  SPREADING_TEMPLATE_TYPES,
  DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES
} from '@utils/constants';
import {ConfirmationPopupComponent} from '@components/shared/popups/confirmation/confirmation-popup.component';
import {TaxMappingLineItem} from '@models/tax-mapping-line-item';
import {StatementService} from '@services/statement.service';
import {
  AddCustomItemToTemplatePopupComponent
} from '@components/main/spreading-template/add-custom-item-to-template-popup/add-custom-item-to-template-popup.component';
import {
  buildAndValidateUpdatePayload,
  TemplatePageData
} from "@components/main/spreading-template/spreading-template-shared-functions";
import {
  BuildUcaEquationComponent
} from '@components/main/spreading-template/build-uca-equation/build-uca-equation.component';
import {
  ExcelExportReferenceUploadComponent
} from "@components/main/spreading-template/edit-spreading-template/excel-export-reference-upload/excel-export-reference-upload.component";
import {LaunchDarklyService} from "@services/launchdarkly.service";
import {
  AddItemToDASSectionPopupComponent, TargetSectionOption
} from "@components/main/spreading-template/add-item-to-das-section-popup/add-item-to-das-section-popup.component";
import {deepCopy} from "@utils/functions";
import {
  SetCommonSizeDenominatorComponent
} from "@components/main/spreading-template/set-common-size-denominator/set-common-size-denominator.component";

@Component({
  selector: 'app-edit-spreading-template',
  templateUrl: './edit-spreading-template.component.html',
  styleUrls: ['./edit-spreading-template.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class EditSpreadingTemplateComponent implements OnInit {
  subsArr$: Subscription[] = [];
  templateType = 'STANDARD';
  DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES=DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES;
  taxonomyNodes;
  dragStartTree;
  taxonomyTreeOptions = {
    idField: 'standardLineItemId',
    displayField: 'label',
    allowDrag: true, // (node) => node.isLeaf,
    useVirtualScroll: true,
    actionMapping: {
      mouse: {
        dragStart: () => {
          this.dragStartTree = this.taxonomyTree
        }
      },
      keys: {
        [KEYS.UP]: (tree, node, $event) => {
          $event.preventDefault();
          return false;
        },
        [KEYS.DOWN]: (tree, node, $event) => {
          $event.preventDefault();
          return false;
        },
        [KEYS.RIGHT]: (tree, node, $event) => {
          $event.preventDefault();
          return false;
        },
        [KEYS.LEFT]: (tree, node, $event) => {
          $event.preventDefault();
          return false;
        }
      }
    }
  };

  workingNodes;
  workingTreeOptions = {
    idField: 'standardLineItemId',
    displayField: 'label',
    allowDrag: this.workingTreeAllowDrag,
    allowDrop: (element, {parent, index}) => this.workingTreeAllowDrop(element, {parent, index}),
    useVirtualScroll: true,
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          this.editNode = node;
          node.focus();
        },
        dragStart: () => {
          this.dragStartTree = this.workingTree
        },
        drop: (tree:TreeModel, node: TreeNode, $event: any, {from, to}) => {
          if (from?.data?.className === "GenericCalculatedItem" && to?.dropOnNode) {
            to.index = to.parent?.data?.children?.length || 0;
          }
          TREE_ACTIONS.MOVE_NODE(tree, node, $event, {from, to});
        },
      },
      keys: {
        [KEYS.UP]: (tree, node, $event) => {
          $event.preventDefault();
          return false;
        },
        [KEYS.DOWN]: (tree, node, $event) => {
          $event.preventDefault();
          return false;
        },
        [KEYS.RIGHT]: (tree, node, $event) => {
          $event.preventDefault();
          return false;
        },
        [KEYS.LEFT]: (tree, node, $event) => {
          $event.preventDefault();
          return false;
        }
      }
    }
  }

  editNode;
  showFormulaOverride = false;
  showItemvalidator = false;
  mode: 'create' | 'update' = 'create';
  templateId = null;
  templateUuid = null;
  editRefMode = false;
  isTemplateNameBeingEdited = false;
  editTemplateMappingsActivated = false;
  taxFormMappingOverrides: { [taxFormType: string]: Array<TaxMappingLineItem>; } = {};
  initialPageData: TemplatePageData;
  submissionPayload: any;
  referenceSheetKey: string = ''
  showDownloadWorkbookButton: boolean = false;
  incomeStatementCommonSizeDenominatorOverrideRef = ''
  balanceSheetCommonSizeDenominatorOverrideRef = ''

  validatorComparerOptions = [
    {value: '', label: 'Choose Comparer'},
    {value: '==', label: 'Equal to'},
    {value: '!=', label: 'not Equal to'},
    {value: '>', label: 'Greater than'},
    {value: '<', label: 'Less than'},
    {value: '>=', label: 'Greater than or equal'},
    {value: '<=', label: 'Less than or equal'}
  ];

  validatorMarker = [
    {value: 'INSUFFICIENT', label: 'INSUFFICIENT'},
    {value: 'SUFFICIENT', label: 'SUFFICIENT'}
  ];

  spreadingTemplate = {
    name: 'New Template',
    isDefault: false
  }

  hotkeys: Array<Hotkey> = [
    new Hotkey('a+down', (evt: KeyboardEvent, combo: string) => {
      // console.log('Combo: ' + combo);
      this.taxonomyTree.treeModel.focusNextNode();
      return false;
    }),
    new Hotkey('a+up', (evt: KeyboardEvent, combo: string) => {
      this.taxonomyTree.treeModel.focusPreviousNode();
      return false;
    }),

    new Hotkey('shift+down', (evt: KeyboardEvent, combo: string) => {
      this.workingTree.treeModel.focusNextNode();
      return false;
    }),
    new Hotkey('shift+up', (evt: KeyboardEvent, combo: string) => {
      this.workingTree.treeModel.focusPreviousNode();
      return false;
    }),

    new Hotkey('shift+right', (evt: KeyboardEvent, combo: string) => {
      this.moveFocusedItemToWorkingTree();
      return false;
    }),
  ];

  @ViewChild('taxonomyTree', {static: true})
  taxonomyTree: TreeComponent;

  @ViewChild('workingTree', {static: true})
  workingTree: TreeComponent;

  constructor(
    private _alertService: AlertService,
    private _hotkeysService: HotkeysService,
    private _sharedDataService: SharedDataService,
    private _spreadingTemplateService: SpreadingTemplateService,
    private popupService: NgxPopupService,
    private _router: Router,
    private _route: ActivatedRoute,
    private downloadService: DownloadService,
    private _statementService: StatementService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this._sharedDataService.pageHeaderTitle$.next('Templates');
    if (this._route.toString().includes('new')) {
      this.mode = 'create';
    } else {
      this.mode = 'update';
    }
    this.getFeatureFlags()

    this.subsArr$.push(this._route.paramMap.subscribe((params) => {
        this.templateType = params.get('template_type');

        this.templateUuid = params.get('template_uuid') || params.get('original_uuid');

        this._spreadingTemplateService.getTemplateByUuid(this.templateUuid).subscribe(template => {
          let numericalTemplateId = null;

          if (template !== undefined) {
            numericalTemplateId = template.id;
          }

          if (typeof numericalTemplateId !== 'number') {
            this.templateId = null;
            this.isTemplateNameBeingEdited = true;
          } else {
            this.templateId = numericalTemplateId;
            this.referenceSheetKey = template.referenceSheetKey
          }
          this.loadData();
        });

      },
      (error) => {
        this._alertService.error('an error has occurred');
        console.log(error);
      }));
  }

  setInitialPageData() {
    try {
      this.initialPageData = {
        metaData: {
          'name': this.spreadingTemplate.name,
          'isDefault': this.spreadingTemplate.isDefault,
        },
        templateData: {
          'items': structuredClone(this.workingNodes),
          'commonSizeDenominatorOverrides': this.retrieveCommonSizeDenominatorOverrides()
        }
      }
    } catch (e) {
      console.error(
        'Failed to create clone of page data for comparison on submission, submissions with no changes will be allowed.'
        , e);
    }
  }

  loadData() {
    if (this.templateId === null) { // no template id
      const sources = [
        this.getFincuraTaxonomyItems(this.templateType),
        this._spreadingTemplateService.getEmptyTemplate(this.templateType),
      ];

      this.subsArr$.push(forkJoin(sources).subscribe(results => {
          this.displayTemplates(results);
        },
        error => {
          console.log('error', error);
          console.log(error.message);
        }));
    } else {
      const sources = [
        this.getFincuraTaxonomyItems(this.templateType),
        this._spreadingTemplateService.getTemplate(this.templateId),
      ];

      this.subsArr$.push(forkJoin(sources).subscribe(results => {
          this.displayTemplates(results);
        },
        error => {
          console.log('error', error);
        }));
    }
  }

  getFeatureFlags() {
    this.showDownloadWorkbookButton = this.launchDarklyService.flags['download-excel-workbook'];
    this.subsArr$.push(this.launchDarklyService.flagChange.subscribe((flags) => {
      this.showDownloadWorkbookButton = flags['download-excel-workbook'];
    }));
  }

  getFincuraTaxonomyItems(templateType: string) {
    if (templateType === 'STANDARD') {
      return this._spreadingTemplateService.getFincuraTaxonomy();
    } else {
      return this._spreadingTemplateService.getAvailableTaxonomyItemsForSingleEntityAnalysis();
    }
  }

  displayTemplates(results: any): void {
    const workingNodeResult = results[1];
    this.workingNodes = workingNodeResult.items;
    this.spreadingTemplate.name = workingNodeResult.name;
    this.spreadingTemplate.isDefault = workingNodeResult.isDefault;
    this.taxFormMappingOverrides = workingNodeResult.taxFormMappingOverrides;
    this.incomeStatementCommonSizeDenominatorOverrideRef = workingNodeResult.commonSizeDenominatorOverrides?.incomeStatement || ''
    this.balanceSheetCommonSizeDenominatorOverrideRef = workingNodeResult.commonSizeDenominatorOverrides?.balanceSheet || ''

    setTimeout(() => {
      const filteredNodes = this.filterTaxonomyNodes(results[0]);
      this.taxonomyNodes = filteredNodes;
      setTimeout(() => {
        if (this.mode == 'update') {
          this.setInitialPageData();
        }
      }, 1);
      this.hotkeys.forEach(hk => {
        this._hotkeysService.add(hk);
      });
    }, 1);
  }

  filterTaxonomyNodes(items) {
    // remove non-static items that are already in the working tree
    if (!items.filter) {
      return [];
    }
    return items.filter((item) => {
      if (item.children && item.children.length) {
        item.children = this.filterTaxonomyNodes(item.children);
      }
      // this filters out any rolled up totals that are already in working template (don't want to do this for standard template)
      if (this.templateType !== 'STANDARD' && typeof item.standardLineItemId === 'string') {
        return !this.idInWorkingNodes(Number(item.standardLineItemId.slice(2)));
      }
      return item.isStatic || !(this.workingTree.treeModel.getNodeById(item.standardLineItemId) && item.children.length === 0);
    });
  }

  idInWorkingNodes(item_id, workingNodes = null) {
    if (!workingNodes) {
      workingNodes = this.workingNodes;
    }
    for (const workingNode of workingNodes) {
      if (item_id === workingNode.standardLineItemId) {
        return true;
      }
      if (workingNode.children !== undefined && workingNode.children.length > 0 && this.idInWorkingNodes(item_id, workingNode.children)) {
        return true;
      }
    }
    return false;
  }

  createTemplate() {
    const data = {
      'name': this.spreadingTemplate.name,
      'items': this.workingNodes,
      'templateType': this.templateType,
      'isDefault': this.spreadingTemplate.isDefault,
      'commonSizeDenominatorOverrides': this.retrieveCommonSizeDenominatorOverrides()
    };

    this._spreadingTemplateService.createTemplate(data).subscribe(result => {
        this._alertService.success('Saved Successfully!');
        this._router.navigate(['spreading-templates']);
      },
      error => {
        if (error.message === '[\'Record with same fields (company, title) already exists\']' ||
          error.message === '[\'Record with same fields (name, template_type) already exists\']') {
          this._alertService.error(`We're sorry. Another template is already called "${this.spreadingTemplate.name}". Can you try a different name please?`);
        } else {
          this._alertService.error(error.message);
        }
      });
  }

  updateTemplate(): void {
    const submissionData: TemplatePageData = {
      metaData: {
        name: this.spreadingTemplate.name,
        isDefault: this.spreadingTemplate.isDefault
      },
      templateData: {
        items: this.workingNodes,
        commonSizeDenominatorOverrides: this.retrieveCommonSizeDenominatorOverrides()
      }
    };

    this.submissionPayload = buildAndValidateUpdatePayload(this.initialPageData, submissionData);
    if (_.isEmpty(this.submissionPayload.payload)) {
      this._alertService.warning("No changes detected. Please edit template items or metadata before saving.");
      return
    }
    this.popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'SAVE TEMPLATE',
        text: 'Are you sure you want to save this template? This cannot be undone and a re-spread will be triggered.',
        confirmButtonText: 'Yes'
      },
      outputs: {
        callback: this.handleUpdateTemplateModalConfirmation.bind(this)
      },
    });
  };

  handleUpdateTemplateModalConfirmation(approved: boolean) {
    if (approved) {
      this._spreadingTemplateService.updateTemplate(this.templateId, this.submissionPayload.payload).subscribe(result => {
        if (this.templateType === 'STANDARD' && this.submissionPayload.templateDataHasChanged) {
          this._statementService.respreadCompaniesAssociatedWithSpreadingTemplate(this.templateId).subscribe()
        }
        this._alertService.success('Saved Successfully! It may take a moment for your data to update');
        this._router.navigate(['spreading-templates']);
      }, error => {
        this._alertService.error(error.message);
      });
    }

  }

  onWorkingTreeNodeFocus(event) {
    this.showFormulaOverride = this.editNode.data.equationOverride !== null && this.editNode.data.equationOverride !== '';

    const node = event.node;
    this.editNode = node;
    this.editRefMode = false;
    this.showItemvalidator = !!this.editNode.data.validator && this.editNode.data.validator.comparer !== '';
  }

  moveFocusedItemToWorkingTree() {
    const focusedWorkingNode = this.workingTree.treeModel.getFocusedNode();
    const focusedTaxonomyNode = this.taxonomyTree.treeModel.getFocusedNode();

    if (!focusedWorkingNode) {
      this._alertService.info('Please select an item on the working tree to move to');
      return;
    }

    if (!focusedWorkingNode.hasChildren) {
      this._alertService.info('Can not move to a leaf node');
      return;
    }

    if (!!this.workingTree.treeModel.getNodeById(focusedTaxonomyNode.id)) {
      this._alertService.info(`Working Tree already contains "${focusedTaxonomyNode.data.label}"`);
      return;
    }


    this.taxonomyTree.treeModel.focusNextNode();
    this.deleteNode(focusedTaxonomyNode, this.taxonomyTree);
    const childArr = focusedWorkingNode.data.children;
    if (childArr.length && typeof (childArr[childArr.length - 1].standardLineItemId) === 'string') {
      childArr.splice(childArr.length - 1, 0, focusedTaxonomyNode.data);
    } else {
      focusedWorkingNode.data.children.push(focusedTaxonomyNode.data);
    }
    this.workingTree.treeModel.update();
  }

  removeItemFromWorkingTree(node: TreeNode) {
    this.editNode = null;
    this.deleteNode(node, this.workingTree);

    // we should really go find the original parent where this node came from and add it to its children
    // but for now just add it to bottom of taxonomy tree

    this.taxonomyNodes = [...this.taxonomyNodes, node.data];
  }

  workingTreeAllowDrag(element) {
    return !element.data.isStatic || !element.parent?.parent;
  }

  workingTreeAllowDrop(element, {parent, index}) {
    // return false and don't allow drop if node already exists in working tree and drag started on taxonomy
    if (this.dragStartTree === this.taxonomyTree && !!this.workingTree.treeModel.getNodeById(element.id)) {
      // this._alertService.info(`Working Tree already contains "${element.data.label}"`);
      return false;
    }


    if (parent.id === 10200) {
      return true;
    }

    // allow adding Cash Flow Statement/UCA Cashflow at tree root
    if ([TAXONOMY_ITEM_IDS.CASH_FLOW_STATEMENT, TAXONOMY_ITEM_IDS.UCA_CASHFLOW_DIRECT, TAXONOMY_ITEM_IDS.UCA_CASHFLOW_INDIRECT].includes(element.id) && parent.parent == null) {
      return true;
    }

    if (element.data.className === 'SubItem' && element.parent.id !== parent.id) {
      // sub items cannot be moved out of the parent they we created in
      return false;
    }

    // return !parent || (parent.parent && parent.hasChildren && parent.children.length > index);

    // return true if we will not be the last element, and not a leaf node.
    return !parent || !parent?.parent || (parent.parent && parent.hasChildren);
  }

  deleteNode(node, tree) {
    const parentNode = node.realParent ? node.realParent : node.treeModel.virtualRoot;
    _.remove(parentNode.data.children, function (child) {
      return child === node.data;
    });
    tree.treeModel.update();
    if (parentNode.data.children.length === 0) {
      parentNode.data.hasChildren = false;
    }
  }

  toggleHideShowOverride(event): void {
    this.showFormulaOverride = event.target.checked;
  }

  toggleHideShowValidator(event): void {
    this.showItemvalidator = event.target.checked;
    if (this.showItemvalidator && !this.editNode.data.validator) {
      this.editNode.data.validator = {
        comparer: '',
        thresholdEquation: '=1.25',
        mark: 'INSUFFICIENT'
      }
    } else {
      this.editNode.data.validator = {
        comparer: '',
        thresholdEquation: '',
        mark: ''
      }
    }
  }

  toggleBold(): void {
    this.editNode.data.displayBold = !this.editNode.data.displayBold;
  }

  toggleIncludable(): void {
    this.editNode.data.isExcludableInOtherCalculations = !this.editNode.data.isExcludableInOtherCalculations;
  }

  addGenericEquation(isDASItem=false): void {
    this.popupService.open({
      componentType: CreateGenericEquationComponent,
      cssClass: 'modal-add-user',
      inputs: {
        isDASItem: isDASItem
      }
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (data.detail) {
          this._addGenericEquationToWorkingTree(data.detail, isDASItem);
        }
      }, {once: true});
    });
  }

    setCommonSizeDenominatorOverrides(): void {
    this.popupService.open({
      componentType: SetCommonSizeDenominatorComponent,
      inputs: {
        incomeStatementItems: this.retrieveFinancialStatementTaxonomyItems(true),
        balanceSheetItems: this.retrieveFinancialStatementTaxonomyItems(false),
        incomeStatementCommonSizeDenominatorRefInput: this.incomeStatementCommonSizeDenominatorOverrideRef,
        balanceSheetCommonSizeDenominatorRefInput: this.balanceSheetCommonSizeDenominatorOverrideRef
      },
      outputs: {
        callback: (data)  => {
            this.incomeStatementCommonSizeDenominatorOverrideRef = data.incomeStatement
            this.balanceSheetCommonSizeDenominatorOverrideRef = data.balanceSheet
        }
      }
    });
  }

  isUcaItem() {
    return this.editNode && this.editNode.hasOwnProperty('parent') && this.editNode.parent && this.editNode.parent.hasOwnProperty('data') && this.editNode.parent.data && [TAXONOMY_ITEM_IDS.UCA_CASHFLOW_DIRECT, TAXONOMY_ITEM_IDS.UCA_CASHFLOW_INDIRECT].includes(this.editNode.parent.data.standardLineItemId);
  }

  openUcaEquationModal(node): void {
    this.popupService.open({
      componentType: BuildUcaEquationComponent,
      cssClass: 'build-uca-equation-modal',
      inputs: {
        node: node,
        workingNodes: this.workingNodes,
        directMethod: this.editNode && this.editNode.hasOwnProperty('parent') && this.editNode.parent && this.editNode.parent.hasOwnProperty('data') && this.editNode.parent.data && TAXONOMY_ITEM_IDS.UCA_CASHFLOW_DIRECT === this.editNode.parent.data.standardLineItemId
      },
      outputs: {
        generatedUcaEquation: (generatedUcaEquation: string) => {
          node.data.equationOverride = generatedUcaEquation;
        }
      },
    });
  }

  addSubItem(node): void {
    this.popupService.open({
      componentType: CreateSubItemComponent,
      inputs: {
        standardLineItemId: node.data.standardLineItemId
      },
      cssClass: 'modal-add-user',
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (data.detail) {
          const focusedWorkingNode = this.workingTree.treeModel.getFocusedNode();
          focusedWorkingNode.data.children.push(data.detail);
          this.workingTree.treeModel.update();
        }
      }, {once: true});
    });
  }

  _addGenericEquationToWorkingTree(genericNode, isDASItem=false) {
    // add das items as a child of selected node, otherwise add custom calcs at the top of the template
    if (isDASItem){
      const focusedWorkingNode = this.workingTree.treeModel.getFocusedNode();
      focusedWorkingNode.data.children.push(genericNode);
    } else {
      this.workingNodes.unshift(genericNode);
    }
    this.workingTree.treeModel.update();
  }

  beginImportExport() {
    const data = {
      'items': this.workingNodes,
      'templateType': this.templateType,
    }
    if (this.templateType === SPREADING_TEMPLATE_TYPES.STANDARD) {
      data['taxFormMappingOverrides'] = this.taxFormMappingOverrides
      data['commonSizeDenominatorOverrides'] = this.retrieveCommonSizeDenominatorOverrides()
    }
    this.popupService.open({
      componentType: ImportExportTemplateComponent,
      cssClass: 'modal-add-api-key',
      inputs: {
        exportJson: JSON.stringify(data),
        templateId: this.templateId
      },
      outputs: {
        onImport: (data: any) => {
          if (data.templateType !== this.templateType) {
            this._alertService.error('Can not import template of different type');
            return;
          }
          this.workingNodes = data.items;
          this.workingTree.treeModel.update();
          if (data.taxFormMappingOverrides && this.templateType === SPREADING_TEMPLATE_TYPES.STANDARD) {
            // there will be older exports that will not have this key, so we should make it resilient
            // non-standard templates don't have overrides
            this.incomeStatementCommonSizeDenominatorOverrideRef = data.commonSizeDenominatorOverrides?.incomeStatement || ''
            this.balanceSheetCommonSizeDenominatorOverrideRef = data.commonSizeDenominatorOverrides?.balanceSheet || ''

            const taxFormOverridesToSave = {
              'taxFormMappingOverrides': data.taxFormMappingOverrides,
              'forceTaxFormMappingOverridesDuringTemplateImport': true
            };
            this._spreadingTemplateService.updateTaxFormMappingOverrides(this.templateId, taxFormOverridesToSave).subscribe( () => {
            }, error => {
              this._alertService.error(error.message);
            });
          }

          this.popupService.closeAll();
          this._alertService.success('Imported Template');
        }
      }
    }).then((popup: NgxPopupComponent) => {
      // do nothing
    });
  }

  downloadFncCsv() {
    this.downloadService.downloadFromBackend(`/api/spreadingtemplates/read_fincura_taxonomy_csv`);
  }

  toggleTemplateNameEditMode() {
    if (this.isTemplateNameBeingEdited && this.spreadingTemplate.name === '') {
      this._alertService.error('Spreading template name cannot be empty');
    } else {
      this.isTemplateNameBeingEdited = !this.isTemplateNameBeingEdited;
    }
  }

  toggleEditTemplateMappings() {
    this.editTemplateMappingsActivated = !this.editTemplateMappingsActivated;
  }

  retrieveFinancialStatementTaxonomyItems(getIncomeStatementItems = true) {
    let taxonomyItems = [];
    for (const taxonomyItem of this.taxonomyNodes.concat(this.workingNodes)) {
      if (getIncomeStatementItems && taxonomyItem.standardLineItemId === TAXONOMY_ITEM_IDS.INCOME_STATEMENT) {
        taxonomyItems = taxonomyItems.concat(this.flattenTaxonomyItems(taxonomyItem.children));
      } else if (!getIncomeStatementItems && taxonomyItem.standardLineItemId === TAXONOMY_ITEM_IDS.BALANCE_SHEET) {
        taxonomyItems = taxonomyItems.concat(this.flattenTaxonomyItems(taxonomyItem.children));
      }
    }
    // removed duplicates, then alphabetize
    taxonomyItems = Array.from(taxonomyItems.reduce((m, t) => m.set(t.label, t), new Map()).values());
    return taxonomyItems.sort((a, b) => a.label.localeCompare(b.label));
  }

  flattenTaxonomyItems(taxonomyItems) {
    let flattenedTaxonomyItems = [];
    for (const taxonomyItem of taxonomyItems) {
      // filters out the rollup taxonomy items because they have string ids
      if (typeof taxonomyItem.standardLineItemId !== 'string') {
        flattenedTaxonomyItems.push(taxonomyItem);
      }
      if (taxonomyItem.children && taxonomyItem.children.length > 0) {
        flattenedTaxonomyItems = flattenedTaxonomyItems.concat(this.flattenTaxonomyItems(taxonomyItem.children));
      }
    }
    return flattenedTaxonomyItems;
  }

  addTemplateSection(): void {
    this.popupService.open({
      componentType: AddCustomItemToTemplatePopupComponent,
      inputs: {
        hasRollup: true
      }
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (data.detail) {
          this.workingNodes[0].children.push(data.detail)
          this.workingTree.treeModel.update()
        }
      }, {once: true});
    });
  }

  addDASSection(): void {
    this.popupService.open({
      componentType: AddCustomItemToTemplatePopupComponent,
      inputs: {
        hasRollup: false,
        title: 'ADD RATIO ANALYSIS TEMPLATE SECTION',
        description: 'Add a new ratio analysis section to the template structure.',
        itemType: 'DAS_SECTION'
      }
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (data.detail) {
          this.workingNodes.push(data.detail);
          this.workingTree.treeModel.update();
        }
      }, {once: true});
    });
  }

  addItemToDASSection(node): void {
    const existingRATSectionsAsOptions: TargetSectionOption[] = this._getAllRATSectionRootNodes();
    this.popupService.open({
      componentType: AddItemToDASSectionPopupComponent,
      inputs: {
        item: deepCopy(node.data),
        dasSectionOptions: existingRATSectionsAsOptions,
        templateId: this.templateId
      }
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (data.detail) {
          const targetNode = this.workingNodes.find(node => node.standardLineItemId == data.detail.targetSectionStandardLineItemId)
          targetNode.children.push(data.detail.item);
          this.workingTree.treeModel.update();
        }
      })
    });
  }

  _getAllRATSectionRootNodes(): TargetSectionOption[] {
    const ratSectionRootNodes = this.workingNodes
      .filter(node => node.className == 'GenericHeaderItem' && node?.isDynamicAdjustmentStatement && node?.dynamicAdjustmentStatementItemType == DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.DAS_SECTION_ROOT_NODE)
      .map(({standardLineItemId, label}) => ({standardLineItemId, label}));
    if (!ratSectionRootNodes.length) {
      this._alertService.error("No Dynamic Adjustment Statement Sections exist. Create a section before adding items.")
      return
    }
    return ratSectionRootNodes;
  }

  addCustomTemplateItem(isDASItem=false): void {
    const focusedWorkingNode = this.workingTree.treeModel.getFocusedNode();
    const parentNodeDocumentType = focusedWorkingNode.data.documentType
    this.popupService.open({
      componentType: AddCustomItemToTemplatePopupComponent,
      inputs: {
        hasRollup: false,
        title: 'ADD CUSTOM TEMPLATE ITEM',
        description: 'Add a custom template item to the working template.',
        confirmButtonText: 'Add Item',
        inputBoxLabel: 'Item Name',
        documentType: parentNodeDocumentType,
        itemType: isDASItem ? 'DAS_MANUAL_INPUT' : 'CUSTOM'
      }
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (data.detail) {
          const focusedWorkingNode = this.workingTree.treeModel.getFocusedNode();
          focusedWorkingNode.data.children.push(data.detail);
          this.workingTree.treeModel.update();

          if(focusedWorkingNode.data.addDefaultLineItemInCategorization) {
            focusedWorkingNode.data.addDefaultLineItemInCategorization = false
          }
        }
      }, {once: true});
    });
  }

  addHeaderItemAsChild(editNode, isDasNode): void {
    let documentType;
    if (!isDasNode) {
      documentType = editNode.data.documentType;
    }
    this.popupService.open({
      componentType: AddCustomItemToTemplatePopupComponent,
      inputs: {
        itemType: 'DAS_HEADER',
        documentType: documentType,
        hasRollup: false,
        title: 'ADD HEADER ITEM',
        description: 'Add a new label-only header item to the selected Dynamic Adjustment Statement section',
        confirmButtonText: 'Add'
      }
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (data.detail) {
          const focusedWorkingNode = this.workingTree.treeModel.getFocusedNode();
          focusedWorkingNode.data.children.push(data.detail);
          this.workingTree.treeModel.update();
        }
      }, {once: true});
    });
  }

  openExcelExportReferenceExport(): void {
    this.popupService.open(
      {
        componentType: ExcelExportReferenceUploadComponent,
        inputs: {
          existingExcelExportReferenceS3Key: this.referenceSheetKey,
          spreadingTemplateUuid: this.templateUuid,
          spreadingTemplateId: this.templateId
        }
      }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        this.referenceSheetKey = data.detail.key
      }, {once: true});
    });
  }

  retrieveCommonSizeDenominatorOverrides() {
    let payload = {}
    if (this.incomeStatementCommonSizeDenominatorOverrideRef !== '') {
      payload["incomeStatement"] = this.incomeStatementCommonSizeDenominatorOverrideRef
    }
    if (this.balanceSheetCommonSizeDenominatorOverrideRef !== '' ) {
      payload["balanceSheet"] = this.balanceSheetCommonSizeDenominatorOverrideRef
    }
    return payload
  }
}
