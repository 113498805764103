import { StatementOption } from '../../../../models/statement-option';
import { DOCUMENT_FILE_TYPE, MANUAL_REVIEW_HEADER_ORDER } from '../../../../utils/constants';
import { MANUAL_REVIEW_HEADERS } from '../../../../utils/constants'
import { CommonFunctions } from '@utils/common-functions';
import Handsontable from "handsontable";

const goodValueRegexp = /^((\((\s+)?(([1-9])([0-9\,\s]+)?|0)\.?([0-9\s]+)?\))|((\-)?(\s+)?(([1-9])([0-9\,\s]+)?|0)\.?([0-9\s]+)?))$/g;

const threeDecimalsRegexp = /^[0-9\,\s]+\.[0-9]{3,}$/g; // checks for numbers or comma separated numbers w/ >= 3 decimal places

const digitsOnlyRegexp = /[^\d.-]/g;

export const notNumberWithDecimalCommaDashRegex = /[^\d,.-]/g;


const goodDateRegexp = /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{4}$/;

export const InvalidValueCellCopy = 'Invalid Number Value. Try checking that this cell contains the correct number value for this line item.';

const InvalidDateCellCopy = 'Invalid Date Header. Try deleting header rows or adding a date in MM/DD/YYYY format.';

const InvalidHistoricalDateCellCopy = 'Invalid Date for Scenario Type: HISTORICAL. Try filling in a date in the past or change Scenario Type to PROJECTION.';

const InvalidLineItemCellCopy = 'Line item name is a date or a number. Try filling in the line item name.';

const CellNumberFormatCopy = 'This cell might may contain parsing errors. Check to ensure the number formatting is correct';

export const PossibleNumDecimalsErrorCellCopy = 'The number of decimals in this cell may be an outlier. Please check that the parsed numbers are accurate.';

export const taxReturnCellFlagCopy = 'This cell may contain parsing errors';

export const ERROR_STATUS = 'error';
export const WARNING_STATUS = 'warning';
export type VALIDATION_STATUS = 'error' | 'warning' | null;


/**
 * Checks if a cell at a particular position passes the validation rules
 * for that position
 */
export function cellIsValid(
  activePageNumber: number,
  row: number,
  col: number,
  text: string,
  statementOptions: Array<Array<Array<StatementOption>>>,
  currency: string
): boolean {
  return !getCellValidationFailure(activePageNumber, row, col, text, statementOptions, currency);
}

export function isValidValueCell(text: string): boolean {
  return (text.length === 0 || !!text.match(goodValueRegexp));
}

export function isWarningCell(text: string, row: number, col: number, validationFailureReason: string = ''): boolean {
  if (col === 0 && row > MANUAL_REVIEW_HEADERS.length - 1) {
    // line item labels can come through as type (text, number, date, emtpy), always a warning
    return true;
  }

  return isValidNumberWithThreeDecimals(text) || validationFailureReason === PossibleNumDecimalsErrorCellCopy;
}

function isValidNumberWithThreeDecimals(text: string): boolean {
  return (text.length === 0 || !!text.match(threeDecimalsRegexp));
}

export function cellNumberFormatIsCompliantWithCurrency(text: string, decimalDelimiter: string) {
  if (text && text.includes(decimalDelimiter)) {
    // three digits following a decimal point are most likely to be parsing issues
    // e.g. "100.000" for US formatted numbers
    return !(text.split(decimalDelimiter)[1].replace(digitsOnlyRegexp, '').length >= 3);
  }
  return true;
}

/**
 * Line item labels can be blank
 */
function isValidLineItemCell(text: string): boolean {
  return text.length === 0 || (!isValidDateCell(text) && !isValidValueCell(text));
}

export function isValidDateCell(text: string): boolean {
  return !!text.match(goodDateRegexp);
}

export function isValidHistoricalDateCell(text: string): boolean {
  return (new Date(text) < new Date());
}

/**
 * Returns the error message for the specific failure for this cell.
 * If the cell has no errors the return value will be an empty string.
 */
export function getCellValidationFailure(
  activePageNumber: number,
  row: number,
  col: number,
  text: string,
  statementOptions: Array<Array<Array<StatementOption>>>,
  currencyDelimiter: string,
  fileType: string = DOCUMENT_FILE_TYPE.UNKNOWN,
  numDecimalsMode = -1,
  handsOnTableInstance?: Handsontable // necessary to get current value of other cells for reference in date validations
): string {
  // A new page that is created does not populate with statement options so we cannot rely on the object
  if (statementOptions[activePageNumber].length === 0) {
    return ''
  }

  if (row >= statementOptions[activePageNumber][col]?.length && col > 0) {
    if (!isValidValueCell(text)) {
      return InvalidValueCellCopy;
    } else if (fileType !== DOCUMENT_FILE_TYPE.XLSX && !cellNumberFormatIsCompliantWithCurrency(text, currencyDelimiter)) {
      return CellNumberFormatCopy;
    } else if (
      numDecimalsMode !== -1 &&
      text !== '' &&
      numDecimalsMode !== CommonFunctions.numDecimals(text) &&
      parseFloat(text) !== 0) {
      return PossibleNumDecimalsErrorCellCopy;
    }
    return '';
  } else if (row < statementOptions[activePageNumber][col]?.length && col === 0) {
    if (text !== statementOptions[activePageNumber][col][row].label) {
      return `Invalid option name. This cell's text should be ${statementOptions[activePageNumber][col][row].label}`;
    }
    return '';
  } else if (row === MANUAL_REVIEW_HEADER_ORDER.STATEMENT_DATE_INDEX && col > 0) {
    if (!isValidDateCell(text)) {
      return InvalidDateCellCopy;
    } if (!isValidHistoricalDateCell(text) && !!handsOnTableInstance && !handsOnTableInstance.isDestroyed && isScenarioTypeHistorical(handsOnTableInstance, col)) {
      return InvalidHistoricalDateCellCopy;
    }
    return '';
  } else if (row >= statementOptions[activePageNumber][col]?.length && col === 0) {
    if (!isValidLineItemCell(text)) {
      return InvalidLineItemCellCopy;
    }
    return '';
  }
  return '';
}

export function isScenarioTypeHistorical(handsOnTableInstance, col){
  return handsOnTableInstance.getDataAtCell(MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX, col) === 'HISTORICAL'
}

/**
 * Returns the error message for the specific failure for this cell.
 * If the cell has no errors the return value will be an empty string.
 */
export function getNonHeaderCellValidationFailure(row: number, col: number, text: string,
  currencyDelimiter: string): string {
    if (col > 0) {
      if (!isValidValueCell(text)) {
        return InvalidValueCellCopy;
      }
      return '';
    } else {
      if (!isValidLineItemCell(text)) {
        return InvalidLineItemCellCopy;
      }
    }
      return '';
  }

  /**
   * Determines if two selection from HandsOnTable overlap at all.
   *
   * An overlap of zero means "these are the same row or column" and thus is legit.
   * @param s1
   * @param s2
   */
  export function selectionsOverlap(s1, s2) {
    const colOverlap = Math.min(s1.end.col as number, s2.end.col as number) - Math.max(s1.start.col, s2.start.col);

    const rowOverlap = Math.min(s1.end.row as number, s2.end.row as number) - Math.max(s1.start.row, s2.start.row);

    return colOverlap >= 0 && rowOverlap >= 0;
  }

  export function mergeSelections(s1, s2) {
    return {
      'start': {
        'row': Math.min(s1.start.row as number, s2.start.row as number),
        'col': Math.min(s1.start.col as number, s2.start.col as number),
      },
      'end': {
        'row': Math.max(s1.start.row as number, s2.start.row as number),
        'col': Math.max(s1.start.col as number, s2.start.col as number),
      }
    }
  }

export interface PageStatusStoreType {
    [page: number]: VALIDATION_STATUS
}

export interface CellStatusStore {
    [page: number]: {
      [cellCoords: string]: VALIDATION_STATUS
    }
}

export interface CellBackendValidationStatusStore {
    [page: number]: {
      [cellCoords: string]: any // INITIAL_INVALID_VALUE | <cell value>
    }
}

export class CellErrorManager {
  /**
   * Structure to store individual cell states for the purpose of determining overall status of a table
   */
  errorStore: any;
  constructor() {
    this.reset();
  }

  reset(): void{
    this.errorStore = {};
  }

  setCellStatus(page: number, row: number, col: number, status: any): void {
    const cellCoordString = CellErrorManager._getErrorCellCoordKey(row, col);
    if (this.errorStore[page]) {
      this.errorStore[page][cellCoordString] = status;
    } else {
      this.errorStore[page] = {[cellCoordString]: status}
    }
  }

  clearCellStatus(page: number, row: number, col: number): void {
    const cellCoordString = CellErrorManager._getErrorCellCoordKey(row, col);
    if (this.errorStore && this.errorStore[page] && this.errorStore[page].hasOwnProperty(cellCoordString)) {
      delete this.errorStore[page][cellCoordString];
    }
  }

  getCellValue(page: number, row: number, col: number): any {
    const cellCoordString = CellErrorManager._getErrorCellCoordKey(row, col);
    if (this.errorStore[page] && this.errorStore[page].hasOwnProperty(cellCoordString)){
      return this.errorStore[page][cellCoordString]
    }
  }

  getPageStatus(page: number): VALIDATION_STATUS {
    throw new Error('method must be implemented in child class')
  }


  private static _getErrorCellCoordKey(row: number, col: number): string {
    return `${row}_${col}`
  }
}

export class FrontendValidationManager extends CellErrorManager {
  errorStore: CellStatusStore;
  getPageStatus(page: number): VALIDATION_STATUS{
    let pageStatus = null;
    if (this.errorStore[page] && Object.keys(this.errorStore[page]).length > 0){
      if (Object.values(this.errorStore[page]).some(cellStatus => cellStatus === ERROR_STATUS)){
        pageStatus = ERROR_STATUS;
      } else if (Object.values(this.errorStore[page]).some(cellStatus => cellStatus === WARNING_STATUS)){
        pageStatus = WARNING_STATUS;
      }
    }
    return pageStatus;
  }
}

export class BackendValidationManager extends CellErrorManager{
    errorStore: CellBackendValidationStatusStore
    getPageStatus(page: number): 'error' | null {
      if (this.errorStore[page] && Object.keys(this.errorStore[page]).length > 0){
        return ERROR_STATUS;
      } else {
        return null
      }
    }
}
