import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import { BuilderFrame, STATEMENT_BUILDER_OPERATORS } from '@components/main/borrower/statement-builder/model';
import { MultiSelectItem } from '@components/shared/popover-menu-multi-select/popover-menu-multi-select.component';
import { Select2OptionData } from '@components/shared/select2/select2.interface';
import { Company } from '@models/company';
import { DataViewColumn, DataViewRow } from '@models/dataview';
import { AlertService } from '@services/alert.service';
import { DataFrameService } from '@services/dataframes.service';
import {DATE_FORMAT, SERVER_DATE_FORMAT, SUPPORTED_CURRENCIES, TAXONOMY_ITEM_IDS} from '@utils/constants';
import * as moment from 'moment';
import {finalize} from "rxjs/operators";
import {Observable} from "rxjs";

declare var bootstrap: any;

@Component({
  selector: 'app-consolidate-periods-form',
  templateUrl: './consolidate-periods-form.component.html',
  styleUrls: ['./consolidate-periods-form.component.scss']
})
export class ConsolidatePeriodsFormComponent implements OnInit, AfterViewInit {
  @Input() company: Company;
  @Input() columns: DataViewColumn[];
  @Input() rows: DataViewRow[];
  @Input() frameToBeEditedId: number;
  @Output() closeConsolidatedPeriods = new EventEmitter<boolean>();

  companyId: Number;
  statementDate: string = null;
  reportingInterval = '';
  selectedBalanceSheetFrameIndex: number = -1;
  selectedBeginningCashFrameIndex: number = -1;
  selectedEndingCashFrameIndex: number = -1;
  isFormComplete = false;
  isSubmitting = false;
  isLoading = false;

  reportingIntervalOptions: Select2OptionData[] = [
    {
      id: 'MONTHLY',
      text: 'MONTHLY'
    },
    {
      id: 'QUARTERLY',
      text: 'QUARTERLY'
    },
    {
      id: 'SEMI_ANNUALLY',
      text: 'SEMI_ANNUALLY'
    },
    {
      id: 'ANNUALLY',
      text: 'ANNUALLY'
    },
    {
      id: 'FISCAL_YTD',
      text: 'FISCAL_YTD'
    },
    {
      id: 'TTM',
      text: 'TTM'
    }
  ];

  periodOptions: Select2OptionData[] = [];
  multiSelectPeriodOptions: MultiSelectItem[] = [];
  multiSelectButtonText = "Select";

  statementDateHasBeenChangedByUser = false;
  hasBeginningCashBeenChangedByUser = false;
  hasEndingCashBeenChangedByUser = false;
  hasBalanceSheetBeenChangedByUser = false;
  isBeginningCashInTable = false;
  isEndingCashInTable = false;
  currency: string;
  supportedCurrencies = SUPPORTED_CURRENCIES;

  constructor(
    private _dataFrameService: DataFrameService,
    private _alertService: AlertService,
  ) { }

  ngOnInit(): void {
    if (this.frameToBeEditedId) {
      this._prefillEditForm()
    } else {
      this.currency = this.company.settings.currency;
    }
    this.columns.forEach((column, colIdx) => {
      const periodLabel = (column.statementDate + " - "  + column.preparationType + " - " + column.reportingInterval + (column.projectionName ? " - " + column.projectionName : ""));
      this.periodOptions.push({
        id: String(colIdx),
        text: periodLabel,
      })
      this.multiSelectPeriodOptions.push({
        id: String(colIdx),
        title: periodLabel,
        action: () => this.includeOrExcludeColumn(colIdx),
        checked: false,
      })
    })
    this.setCashFlowStatementFlags();
  }

  ngAfterViewInit() {
    this.enableTooltips()
  }

  enableTooltips() {
    // Bootstrap tooltip initialization
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach(tooltipTriggerEl => {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  _prefillEditForm(){
    this.isLoading = true;
    this._dataFrameService.loadPrefillDataForCombinedFrame(this.frameToBeEditedId)
      .pipe(finalize(() => { this.isLoading = false }))
      .subscribe(res => {
        res.selectedPeriodOptions.forEach(periodUuid => {
          const periodIndex = this.columns.findIndex(col => col.periodKeyUuid === periodUuid);
          this.includeOrExcludeColumn(periodIndex);
        });
        this.reportingInterval = res.reportingInterval;
        this.selectedBalanceSheetFrameIndex = this.columns.findIndex(col => col.periodKeyUuid === res.selectedBalanceSheet);
        if (this.isBeginningCashInTable && res?.selectedBeginningCashFrame) {
          this.selectedBeginningCashFrameIndex = this.columns.findIndex(col => col.periodKeyUuid === res.selectedBeginningCashFrame);
        }
        if (this.isEndingCashInTable && res?.selectedEndingCashFrame) {
          this.selectedEndingCashFrameIndex = this.columns.findIndex(col => col.periodKeyUuid === res.selectedEndingCashFrame);
        }
        this.statementDate = res.statementDate;
        this.currency = res.currency;
      }, error => {
        console.error('failed to load prefill values: ', error)
      });
  }

  includeOrExcludeColumn(colIdx: number) {
    this.multiSelectPeriodOptions[colIdx].checked = !this.multiSelectPeriodOptions[colIdx].checked;
    this.updateAutofilledNewPeriodData();
    this.setIsFormComplete();
    this.updateMultiSelectButtonText();
  }

  updateMultiSelectButtonText() {
    const countSelectedPeriods = this.multiSelectPeriodOptions.reduce((count, option) => count + (option.checked === true ? 1 : 0), 0);
    if (countSelectedPeriods < 1) {
      this.multiSelectButtonText = 'Select Periods'
    } else if (countSelectedPeriods === 1) {
      this.multiSelectButtonText = "1 period selected";
    } else {
      this.multiSelectButtonText = String(countSelectedPeriods) + " periods selected";
    }
  }

  updateReportingInterval(reportingInterval: string) {
    this.reportingInterval = reportingInterval;
    this.setIsFormComplete();
  }

  updateStatementDate(event) {
    this.statementDate = event.dateObject.formatted;
    this.setIsFormComplete();
    this.statementDateHasBeenChangedByUser = true;
  }

  updateSelectedBalanceSheetFrameIndex(selectedBalanceSheetFrameIndex: string) {
    this.selectedBalanceSheetFrameIndex = Number(selectedBalanceSheetFrameIndex);
    this.setIsFormComplete();
    this.hasBalanceSheetBeenChangedByUser = true;
  }

  updateSelectedBeginningCashFrameIndex(selectedBeginningCashFrameIndex: string) {
    this.selectedBeginningCashFrameIndex = Number(selectedBeginningCashFrameIndex);
    this.setIsFormComplete();
    this.hasBeginningCashBeenChangedByUser = true;
  }

  updateSelectedEndingCashFrameIndex(selectedEndingCashFrameIndex: string) {
    this.selectedEndingCashFrameIndex = Number(selectedEndingCashFrameIndex);
    this.setIsFormComplete();
    this.hasEndingCashBeenChangedByUser = true;
  }

  setIsFormComplete(): void {
    if (this.multiSelectPeriodOptions.some(option=> option.checked === true) && this.reportingInterval && this.statementDate && this.selectedBalanceSheetFrameIndex !== -1) {
      if (this.isBeginningCashInTable && this.selectedBeginningCashFrameIndex === -1) {
        this.isFormComplete = false;
        return
      }
      if (this.isEndingCashInTable && this.selectedEndingCashFrameIndex === -1) {
        this.isFormComplete = false;
        return
      }
      this.isFormComplete = true;
    } else {
      this.isFormComplete = false;
    }
  }

  triggerCloseConsolidatePeriods(newStatementCreated=false) {
    this.closeConsolidatedPeriods.emit(newStatementCreated);
  }

  generateConsolidatedStatement() {
    this.isSubmitting = true;
    let submitRequest$: Observable<any>;
    const sharedRequestArgs: [number, string, string, Array<any>, any, any, any, string, any, 'CONSOLIDATED' | 'CALCULATED'] = [
      this.company.id,
      moment(this.statementDate, DATE_FORMAT).format(SERVER_DATE_FORMAT),
      this.reportingInterval,
      this.prepareIncomeStatmentFrames(),
      this.colToFrameKey(this.columns[this.selectedBalanceSheetFrameIndex]),
      this.selectedBeginningCashFrameIndex === -1 ? undefined : this.colToFrameKey(this.columns[this.selectedBeginningCashFrameIndex]),
      this.selectedEndingCashFrameIndex === -1 ? undefined : this.colToFrameKey(this.columns[this.selectedEndingCashFrameIndex]),
      this.currency,
      this._buildFormSnapshot(),
      "CONSOLIDATED"
    ];
    if (this.frameToBeEditedId) {
      submitRequest$ = this._dataFrameService.updateCombinedFrame(this.frameToBeEditedId, ...sharedRequestArgs);
    } else {
      submitRequest$ = this._dataFrameService.createCombinedFrame(...sharedRequestArgs);
    }
    submitRequest$.subscribe((data) => {
      this._alertService.success('Statement Built! If the new statement isn\'t shown, please reload the page.');
      this.triggerCloseConsolidatePeriods(true);
      this.isSubmitting = false;
    }, (err) => {
      this._alertService.error(`${err.message}`);
      this.isSubmitting = false;
    })
  }

  _buildFormSnapshot(){
    let baseSnapshot = {
      selectedPeriodOptions: this.multiSelectPeriodOptions
      .filter(option => option.checked)
      .map(option => this.columns[option.id]?.periodKeyUuid),
      reportingInterval: this.reportingInterval,
      statementDate: this.statementDate,
      selectedBalanceSheet: this.columns[this.selectedBalanceSheetFrameIndex]?.periodKeyUuid,
      currency: this.currency,
    };
    if (this.isBeginningCashInTable){
      baseSnapshot['selectedBeginningCashFrame'] = this.columns[this.selectedBeginningCashFrameIndex]?.periodKeyUuid
    }
    if (this.isEndingCashInTable){
      baseSnapshot['selectedEndingCashFrame'] = this.columns[this.selectedEndingCashFrameIndex]?.periodKeyUuid
    }
    return baseSnapshot
  }

  prepareIncomeStatmentFrames() {
    const incomeStatementFrames = [];
    this.columns.forEach((column, colIdx) => {
      if (this.multiSelectPeriodOptions[colIdx].checked) {
        incomeStatementFrames.push(this.getBuilderFrameForColumn(column))
      }
    })
    return incomeStatementFrames;
  }

  getBuilderFrameForColumn(column: DataViewColumn) {
    return {
      frame_key: this.colToFrameKey(column),
      operator: STATEMENT_BUILDER_OPERATORS.add
    }
  }

  colToFrameKey(column: DataViewColumn) {
    return {
      scenario: column.scenario,
      statement_date: moment(column.statementDate, DATE_FORMAT).format(SERVER_DATE_FORMAT),
      reporting_interval: column.reportingInterval,
      preparation_type: column.preparationType,
      projection_name: column.projectionName,
    }
  }

  selectOrDeselectAll() {
    if (this.multiSelectPeriodOptions.some(option => option.checked === true)) {
      this.multiSelectPeriodOptions.forEach(option => option.checked = false);
    } else {
      this.multiSelectPeriodOptions.forEach(option => option.checked = true);
    }
    this.updateAutofilledNewPeriodData();
    this.updateMultiSelectButtonText();
    this.setIsFormComplete();
  }

  updateAutofilledNewPeriodData() {
    if (!this.hasBalanceSheetBeenChangedByUser) {
      this.selectedBalanceSheetFrameIndex = this.retreiveEarliestOrLatestIncludedColumnIdx(false);
    }
    if (this.isBeginningCashInTable && !this.hasBeginningCashBeenChangedByUser) {
      this.selectedBeginningCashFrameIndex = this.retreiveEarliestOrLatestIncludedColumnIdx(true);
    }
    if (this.isEndingCashInTable && !this.hasEndingCashBeenChangedByUser) {
      this.selectedEndingCashFrameIndex = this.retreiveEarliestOrLatestIncludedColumnIdx(false);
    }
    if (!this.statementDateHasBeenChangedByUser) {
      this.statementDate = this.retreiveEarliestOrLatestIncludedColumnStatementDate(false);
    }
  }

  setCashFlowStatementFlags() {
    this.rows.forEach((row) => {
      if (row.lineItem.id === TAXONOMY_ITEM_IDS.TOTAL_BEGINNING_CASH) {
        this.isBeginningCashInTable = true;
      } else if (row.lineItem.id === TAXONOMY_ITEM_IDS.TOTAL_ENDING_CASH) {
        this.isEndingCashInTable = true;
      }
    })
  }

  retreiveEarliestOrLatestIncludedColumnIdx(getEarliest = true) {
    let column = null;
    let colIdx = null;
    this.multiSelectPeriodOptions.forEach((option, idx) => {
      if (option.checked) {
        const includedColumn = this.columns[idx];
        if (column === null ||
          (getEarliest && new Date(includedColumn.statementDate) < new Date(column.statementDate)) ||
          (!getEarliest && new Date(includedColumn.statementDate) > new Date(column.statementDate))) {
          column = includedColumn;
          colIdx = idx;
        }
      }
    })
    return colIdx;
  }

  retreiveEarliestOrLatestIncludedColumnStatementDate(getEarliest = true) {
    let column = null;
    this.multiSelectPeriodOptions.forEach((option, idx) => {
      if (option.checked) {
        const includedColumn = this.columns[idx];
        if (column === null ||
          (getEarliest && new Date(includedColumn.statementDate) < new Date(column.statementDate)) ||
          (!getEarliest && new Date(includedColumn.statementDate) > new Date(column.statementDate))) {
          column = includedColumn;
        }
      }
    })
    return column !== null ? column.statementDate : null;
  }

  changeCurrency(currency: string) {
    this.currency = currency;
  }

}
