<div *ngIf="isLoading">
    <div class="loader__container">
        <h2>Loading</h2>
        <div class="loader"></div>
    </div>
</div>

<div class="full-container" *ngIf="!isLoading">
    <app-locked-takeover
        *ngIf="isReviewQueueItemLocked"
        [lastLockedBy]="reviewQueueItem.lastLockedBy"
    ></app-locked-takeover>

    <app-digitization-top-nav
        [document]="reviewQueueItem"
        (saveMethod)="save()"
        (nextMethod)="next()"
    >
        <div class="toggler">
            <span>View All Pages</span>
            <i *ngIf="!areAllPagesShown" class="fas fa-toggle-off" (click)="togglePagesShown()"></i>
            <i *ngIf="areAllPagesShown" class="fas fa-toggle-on" (click)="togglePagesShown()"></i>
        </div>
        <div>
            <div class="d-flex flex-row align-items-center flex-wrap">
                <label class="currency d-flex align-items-center">Set Currency </label>
                <select class="form-select w-auto" (change)="changeCurrency($event.target.value)">
                    <option *ngFor="let currency of supportedCurrencies" [value]="currency.key"
                            [selected]="currency.key === this.documentFile.currency">
                        {{currency.key}} ({{currency.symbol}})
                    </option>
                </select>
            </div>
        </div>
    </app-digitization-top-nav>


    <div class="main-wrapper" #wrapper>
        <ng-container *ngIf="_sharedDataService.shouldShowManualReviewSidebar">
            <app-maximized-sidebar
                [stepHeader]="stepHeader"
                [tipsBody]="tipsBody"
                [steps]="steps"
                [fullGif]="fullGif"
                [title]="'Column Adjustments'"
                (toggleSidebar)="toggleStepsToSuccess()">
            </app-maximized-sidebar>
        </ng-container>

        <ng-container *ngIf="!_sharedDataService.shouldShowManualReviewSidebar">
        </ng-container>
        <div class="thumbnail-sidebar">
            <ul class="page-thumbnails" appImgIntersectionObxList>
                <label class="select-page-label page-label">Select page</label>
                <li *ngFor="let page of pagesToShow; let i = index"
                    appImgIntersectionObx
                    #intersection="intersection"
                    [id]="'thumbnail-' + i"
                    #thumbnailItem>
                    <app-box-drawer
                        *ngIf="intersection.hasIntersected; else imagePlaceholder"
                        [sidebar]="_sharedDataService.shouldShowManualReviewSidebar"
                        [allowEdit]="false"
                        [inputImageWidth]="300"
                        [boxes]="boxesByPage?.[page.pageIndex]"
                        [showActiveBorder]="page.pageIndex == activePageIndex"
                        (boxClicked)="boxClicked($event, page.pageIndex)"
                        [sourceImageUrl]="page.thumbnail.signedUrl"
                    ></app-box-drawer>
                    <ng-template #imagePlaceholder>
                        <div class="image-placeholder">
                            ...
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
        <ng-container *ngIf="pagesWithBoxesDrawn.length > 0; else emptyState">
            <div id="review-table-tabulation-container">
            <label class = "adjust-columns-label page-label">Adjust columns</label>
                <app-box-drawer
                    [sidebar]="_sharedDataService.shouldShowManualReviewSidebar"
                    *ngIf="tablesByPage?.[activePageIndex]?.[activeTableIndex]"
                    [allowEdit]="true"
                    [inputImageWidth]="600"
                    [boxes]="tablesByPage[activePageIndex][activeTableIndex].columnStructure"
                    [sourceImageUrl]="documentFile.pageImages[activePageIndex].normalRes.signedUrl"
                    scrollingContainerElementId="review-table-tabulation-container"
                ></app-box-drawer>
            </div>

            <div class="tabulation-output col-3 flex-shrink-1">
                <label class = "selected-data-label page-label">Selected data</label>
                <table class="tabulation" *ngIf="tablesByPage?.[activePageIndex]?.[activeTableIndex]">
                    <tbody>
                    <tr *ngFor="let row of tablesByPage[activePageIndex][activeTableIndex].cells">
                        <td class="ellipses"
                            [ngClass]="{
                    'fs-exclude': cell.columnNumber == 0,
                    'fs-block': cell.columnNumber == 0
                }"
                            *ngFor="let cell of row"
                            title="{{row.label}}"
                        >{{cell.rawText}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <ng-template #emptyState>
            <div class="error-state-container">
                <app-table-empty-state
                    emptyStateIcon="fa-file-circle-question"
                    headerText="No tables found"
                    helpText="Please return to the Table Identification step, draw tables and click 'Next'"
                ></app-table-empty-state>
            </div>
        </ng-template>
    </div>
</div>
