import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { SourceComment } from '@components/main/spreading/models/source-comment';


@Component({
  selector: 'app-double-click-to-edit',
  templateUrl: './double-click-to-edit.component.html',
  styleUrls: ['./double-click-to-edit.component.scss']
})
export class DoubleClickToEditComponent implements OnInit, OnChanges {
  @Input() value = '';
  @Input() sourceComments: Array<SourceComment>;
  @Input() isEditingDisabled: boolean = false;
  @Output() changed = new EventEmitter<string>();
  @Output() enteredEditMode = new EventEmitter<boolean>();
  @Output() valueBeforeEditing = new EventEmitter<string>
  @Input() editing = false;
  @Input() alignRight = false;
  @Input() formatAsCurrency = false;
  @Input() hasPadding = false;
  @Input() invertValue?: boolean = false;
  @Input() footnotePresent?: boolean = false;
  currentValue = '';
  originalValue: string = '';

  @ViewChild('inputField') inputField: any;

  constructor() { }

  ngOnInit() {
    if (this.formatAsCurrency && !/^-?\d+(\.\d+)?$/.test(this.value)) {
      this.formatAsCurrency = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('value')) {
      this.currentValue = changes['value'].currentValue;
    }
    if (changes.hasOwnProperty('editing') && !changes.editing.firstChange) {
      if (changes['editing'].currentValue) {
        this.originalValue = this.currentValue;
        setTimeout(() => {
          this.inputField.nativeElement.focus();
        });
      } else {
        if (changes['editing'].currentValue !== changes['editing'].previousValue) {
          this.changed.emit(this.currentValue);
        }
      }
    }
  }

  startEditing(evt) {
    if (this.isEditingDisabled) {
      return
    }
    this.stopPropagation(evt);
    this.enteredEditMode.emit(true);
    this.valueBeforeEditing.emit(this.value)
  }

  stopPropagation(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  checkForEnter(evt) {
    if (evt.keyCode === 13) {
      this.originalValue = this.currentValue;
      console.log('Input field:', this.inputField);
      this.stopPropagation(evt);
      this.changed.emit(this.currentValue);
    }

    if(evt.key == "Escape") {
        this.currentValue = this.originalValue;
    }
  }
}
