import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import { BuilderFrame, STATEMENT_BUILDER_OPERATORS } from '@components/main/borrower/statement-builder/model';
import { MultiSelectItem } from '@components/shared/popover-menu-multi-select/popover-menu-multi-select.component';
import { Select2OptionData } from '@components/shared/select2/select2.interface';
import { Company } from '@models/company';
import { DataViewColumn, DataViewRow } from '@models/dataview';
import { AlertService } from '@services/alert.service';
import { DataFrameService } from '@services/dataframes.service';
import {DATE_FORMAT, SERVER_DATE_FORMAT, SUPPORTED_CURRENCIES, TAXONOMY_ITEM_IDS} from '@utils/constants';
import * as moment from 'moment';
import {finalize} from "rxjs/operators";
import {Observable} from "rxjs";

declare var bootstrap: any;

@Component({
  selector: 'app-calculate-missing-period-form',
  templateUrl: './calculate-missing-period-form.component.html',
  styleUrls: ['./calculate-missing-period-form.component.scss']
})
export class CalculateMissingPeriodFormComponent implements OnInit, AfterViewInit {
  @Input() company: Company;
  @Input() columns: DataViewColumn[];
  @Input() rows: DataViewRow[];
  @Input() frameToBeEditedId: number;
  @Output() closeMissingStatementForm = new EventEmitter<boolean>();

  companyId: Number;
  statementDate: string = null;
  reportingInterval = '';
  selectedBalanceSheetFrameIndex: number = -1;
  selectedBeginningCashFrameIndex: number = -1;
  selectedEndingCashFrameIndex: number = -1;
  isFormComplete = false;
  isSubmitting = false;
  isLoading = false;

  reportingIntervalOptions: Select2OptionData[] = [
    {
      id: 'MONTHLY',
      text: 'MONTHLY'
    },
    {
      id: 'QUARTERLY',
      text: 'QUARTERLY'
    },
    {
      id: 'SEMI_ANNUALLY',
      text: 'SEMI_ANNUALLY'
    },
    {
      id: 'ANNUALLY',
      text: 'ANNUALLY'
    },
    {
      id: 'FISCAL_YTD',
      text: 'FISCAL_YTD'
    },
    {
      id: 'TTM',
      text: 'TTM'
    }
  ];

  periodOptions: Select2OptionData[] = [];
  multiSelectPeriodOptionsAddition: MultiSelectItem[] = [];
  multiSelectPeriodOptionsSubtraction: MultiSelectItem[] = [];
  multiSelectButtonTextAddition = "Select existing statement(s)";
  multiSelectButtonTextSubtraction = "Select existing statement(s)";

  statementDateHasBeenChangedByUser = false;
  hasBeginningCashBeenChangedByUser = false;
  hasEndingCashBeenChangedByUser = false;
  hasBalanceSheetBeenChangedByUser = false;
  isBeginningCashInTable = false;
  isEndingCashInTable = false;
  currency: string;
  supportedCurrencies = SUPPORTED_CURRENCIES;


  constructor(
    private _dataFrameService: DataFrameService,
    private _alertService: AlertService,
  ) { }

  ngOnInit(): void {
    if (this.frameToBeEditedId){
      this._prefillEditForm();
    } else {
      this.currency = this.company.settings.currency;
    }
    this.columns.forEach((column, colIdx) => {
      const periodLabel = (column.statementDate + " - "  + column.preparationType + " - " + column.reportingInterval + (column.projectionName ? " - " + column.projectionName : ""));
      this.periodOptions.push({
        id: String(colIdx),
        text: periodLabel,
      })
      this.multiSelectPeriodOptionsAddition.push({
        id: String(colIdx),
        title: periodLabel,
        action: () => this.includeOrExcludeColumnAddition(colIdx),
        checked: false,
      })
      this.multiSelectPeriodOptionsSubtraction.push({
        id: String(colIdx),
        title: periodLabel,
        action: () => this.includeOrExcludeColumnSubtraction(colIdx),
        checked: false,
      })
    })
    this.setCashFlowStatementFlags();
  }

  ngAfterViewInit() {
    this.enableTooltips()
  }

  enableTooltips() {
    // Bootstrap tooltip initialization
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach(tooltipTriggerEl => {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  _prefillEditForm() {
    this.isLoading = true;
    this._dataFrameService.loadPrefillDataForCombinedFrame(this.frameToBeEditedId)
      .pipe(finalize(() => {
        this.isLoading = false
      }))
      .subscribe(res => {
        res.selectedAdditionPeriods.forEach(periodUuid => {
          const periodIndex = this.columns.findIndex(col => col.periodKeyUuid === periodUuid);
          this.includeOrExcludeColumnAddition(periodIndex);
        });
        res.selectedSubtractionPeriods.forEach(periodUuid => {
          const periodIndex = this.columns.findIndex(col => col.periodKeyUuid === periodUuid);
          this.includeOrExcludeColumnSubtraction(periodIndex);
        });

        this.reportingInterval = res.reportingInterval;
        this.selectedBalanceSheetFrameIndex = this.columns.findIndex(col => col.periodKeyUuid === res.selectedBalanceSheet);

        if (this.isBeginningCashInTable && res?.selectedBeginningCashFrame) {
          this.selectedBeginningCashFrameIndex = this.columns.findIndex(col => col.periodKeyUuid === res.selectedBeginningCashFrame);
        }
        if (this.isEndingCashInTable && res?.selectedEndingCashFrame) {
          this.selectedEndingCashFrameIndex = this.columns.findIndex(col => col.periodKeyUuid === res.selectedEndingCashFrame);
        }
        this.statementDate = res.statementDate;
        this.currency = res.currency;
      }, error => {
        console.error('failed to load prefill values: ', error)
      });
  }

  includeOrExcludeColumnAddition(colIdx: number) {
    this.multiSelectPeriodOptionsAddition[colIdx].checked = !this.multiSelectPeriodOptionsAddition[colIdx].checked;
    this.updateAutofilledNewPeriodData();
    this.setIsFormComplete();
    this.updateMultiSelectOptionsSubtractionHideAttribute();
    this.updateMultiSelectButtonText();
  }

  includeOrExcludeColumnSubtraction(colIdx: number) {
    this.multiSelectPeriodOptionsSubtraction[colIdx].checked = !this.multiSelectPeriodOptionsSubtraction[colIdx].checked;
    this.updateAutofilledNewPeriodData();
    this.setIsFormComplete();
    this.updateMultiSelectOptionsAdditionHideAttribute();
    this.updateMultiSelectButtonText(false);
  }

  updateMultiSelectButtonText(forAdditionPeriods=true) {
    const periods = forAdditionPeriods ? this.multiSelectPeriodOptionsAddition : this.multiSelectPeriodOptionsSubtraction;
    const countSelectedPeriods = periods.reduce((count, option) => count + (option.checked === true ? 1 : 0), 0);
    let buttonText = ''
    if (countSelectedPeriods < 1) {
      buttonText = 'Select existing statement(s)'
    } else if (countSelectedPeriods === 1) {
      buttonText = "1 period selected";
    } else {
      buttonText = String(countSelectedPeriods) + " periods selected";
    }
    if (forAdditionPeriods) {
      this.multiSelectButtonTextAddition = buttonText
    } else {
      this.multiSelectButtonTextSubtraction = buttonText;
    }
  }

  updateReportingInterval(reportingInterval: string) {
    this.reportingInterval = reportingInterval;
    this.setIsFormComplete();
  }

  updateStatementDate(event) {
    this.statementDate = event.dateObject.formatted;
    this.setIsFormComplete();
  }

  updateSelectedBalanceSheetFrameIndex(selectedBalanceSheetFrameIndex: string) {
    this.selectedBalanceSheetFrameIndex = Number(selectedBalanceSheetFrameIndex);
    this.setIsFormComplete();
  }

  updateSelectedBeginningCashFrameIndex(selectedBeginningCashFrameIndex: string) {
    this.selectedBeginningCashFrameIndex = Number(selectedBeginningCashFrameIndex);
    this.setIsFormComplete();
    this.hasBeginningCashBeenChangedByUser = true;
  }

  updateSelectedEndingCashFrameIndex(selectedEndingCashFrameIndex: string) {
    this.selectedEndingCashFrameIndex = Number(selectedEndingCashFrameIndex);
    this.setIsFormComplete();
    this.hasEndingCashBeenChangedByUser = true;
  }

  setIsFormComplete(): void {
    if ((this.multiSelectPeriodOptionsAddition.some(option=> option.checked === true) || this.multiSelectPeriodOptionsSubtraction.some(option=> option.checked === true)) && this.reportingInterval && this.statementDate && this.selectedBalanceSheetFrameIndex !== -1) {
      if (this.isBeginningCashInTable && this.selectedBeginningCashFrameIndex === -1) {
        this.isFormComplete = false;
        return
      }
      if (this.isEndingCashInTable && this.selectedEndingCashFrameIndex === -1) {
        this.isFormComplete = false;
        return
      }
      this.isFormComplete = true;
    } else {
      this.isFormComplete = false;
    }
  }

  triggerCloseMissingStatementForm(newStatementCreated=false) {
    this.closeMissingStatementForm.emit(newStatementCreated);
  }

  generateStatementForMissingPeriod() {
    this.isSubmitting = true;
    let submitRequest$: Observable<any>;
    const sharedRequestArgs: [number, string, string, Array<any>, any, any, any, string, any, 'CONSOLIDATED' | 'CALCULATED'] = [
      this.company.id,
      moment(this.statementDate, DATE_FORMAT).format(SERVER_DATE_FORMAT),
      this.reportingInterval,
      this.prepareIncomeStatementFrames(),
      this.colToFrameKey(this.columns[this.selectedBalanceSheetFrameIndex]),
      this.selectedBeginningCashFrameIndex === -1 ? undefined : this.colToFrameKey(this.columns[this.selectedBeginningCashFrameIndex]),
      this.selectedEndingCashFrameIndex === -1 ? undefined : this.colToFrameKey(this.columns[this.selectedEndingCashFrameIndex]),
      this.currency,
      this._buildFormSnapshot(),
      "CALCULATED"
    ];
    if (this.frameToBeEditedId) {
      submitRequest$ = this._dataFrameService.updateCombinedFrame(this.frameToBeEditedId, ...sharedRequestArgs);
    } else {
      submitRequest$ = this._dataFrameService.createCombinedFrame(...sharedRequestArgs);
    }
    submitRequest$.subscribe((data) => {
      this._alertService.success('Statement Built! If the new statement isn\'t shown, please reload the page.');
      this.triggerCloseMissingStatementForm(true);
    }, (err) => {
      this._alertService.error(`${err.message}`);
      this.isSubmitting = false
    })
  };

  _buildFormSnapshot() {
    let baseSnapshot = {
      selectedAdditionPeriods: this.multiSelectPeriodOptionsAddition
        .filter(option => option.checked)
        .map(option => this.columns[option.id]?.periodKeyUuid),
      selectedSubtractionPeriods: this.multiSelectPeriodOptionsSubtraction
        .filter(option => option.checked)
        .map(option => this.columns[option.id]?.periodKeyUuid),
      statementDate: this.statementDate,
      reportingInterval: this.reportingInterval,
      selectedBalanceSheet: this.columns[this.selectedBalanceSheetFrameIndex]?.periodKeyUuid,
      currency: this.currency,
    };
    // todo find test cases for this
    if (this.isBeginningCashInTable) {
      baseSnapshot['selectedBeginningCashFrame'] = this.columns[this.selectedBeginningCashFrameIndex]?.periodKeyUuid
    }
    if (this.isEndingCashInTable) {
      baseSnapshot['selectedEndingCashFrame'] = this.columns[this.selectedEndingCashFrameIndex]?.periodKeyUuid
    }
    return baseSnapshot
  };

  prepareIncomeStatementFrames() {
    const incomeStatementFrames = [];
    this.columns.forEach((column, colIdx) => {
      if (this.multiSelectPeriodOptionsAddition[colIdx].checked) {
        incomeStatementFrames.push(this.getBuilderFrameForColumn(column, STATEMENT_BUILDER_OPERATORS.add))
      }
      if (this.multiSelectPeriodOptionsSubtraction[colIdx].checked) {
        incomeStatementFrames.push(this.getBuilderFrameForColumn(column, STATEMENT_BUILDER_OPERATORS.subtract))
      }
    })
    return incomeStatementFrames;
  }

  getBuilderFrameForColumn(column: DataViewColumn, operator: STATEMENT_BUILDER_OPERATORS) {
    return {
      frame_key: this.colToFrameKey(column),
      operator: operator
    }
  }

  colToFrameKey(column: DataViewColumn) {
    return {
      scenario: column.scenario,
      statement_date: moment(column.statementDate, DATE_FORMAT).format(SERVER_DATE_FORMAT),
      reporting_interval: column.reportingInterval,
      preparation_type: column.preparationType,
      projection_name: column.projectionName,
    }
  }

  selectOrDeselectAllAddition() {
    if (this.multiSelectPeriodOptionsAddition.some(option => option.checked === true)) {
      this.multiSelectPeriodOptionsAddition.forEach((option, idx) => !this.multiSelectPeriodOptionsSubtraction[idx].checked ? option.checked = false : undefined);

    } else {
      this.multiSelectPeriodOptionsAddition.forEach((option, idx) => !this.multiSelectPeriodOptionsSubtraction[idx].checked ? option.checked = true : undefined);
    }
    this.updateAutofilledNewPeriodData();
    this.updateMultiSelectButtonText();
    this.updateMultiSelectOptionsSubtractionHideAttribute()
    this.setIsFormComplete();
  }

  selectOrDeselectAllSubtraction() {
    if (this.multiSelectPeriodOptionsSubtraction.some(option => option.checked === true)) {
      this.multiSelectPeriodOptionsSubtraction.forEach((option, idx) => !this.multiSelectPeriodOptionsAddition[idx].checked ? option.checked = false : undefined);
    } else {
      this.multiSelectPeriodOptionsSubtraction.forEach((option, idx) => !this.multiSelectPeriodOptionsAddition[idx].checked  ? option.checked = true : undefined);
    }
    this.updateAutofilledNewPeriodData();
    this.updateMultiSelectButtonText(false);
    this.updateMultiSelectOptionsAdditionHideAttribute();
    this.setIsFormComplete();
  }

  updateAutofilledNewPeriodData() {
    if (!this.hasBalanceSheetBeenChangedByUser) {
      this.selectedBalanceSheetFrameIndex = this.retreiveEarliestOrLatestIncludedColumnIdx(false);
    }
    if (this.isBeginningCashInTable && !this.hasBeginningCashBeenChangedByUser) {
      this.selectedBeginningCashFrameIndex = this.retreiveEarliestOrLatestIncludedColumnIdx(true);
    }
    if (this.isEndingCashInTable && !this.hasEndingCashBeenChangedByUser) {
      this.selectedEndingCashFrameIndex = this.retreiveEarliestOrLatestIncludedColumnIdx(false);
    }
    if (!this.statementDateHasBeenChangedByUser) {
      this.statementDate = this.retreiveEarliestOrLatestIncludedColumnStatementDate(false);
    }
  }

  setCashFlowStatementFlags() {
    this.rows.forEach((row) => {
      if (row.lineItem.id === TAXONOMY_ITEM_IDS.TOTAL_BEGINNING_CASH) {
        this.isBeginningCashInTable = true;
      } else if (row.lineItem.id === TAXONOMY_ITEM_IDS.TOTAL_ENDING_CASH) {
        this.isEndingCashInTable = true;
      }
    })
  }

  updateMultiSelectOptionsAdditionHideAttribute() {
    this.multiSelectPeriodOptionsSubtraction.forEach((option, idx) => {
      if (option.checked) {
        this.multiSelectPeriodOptionsAddition[idx].hide = true;
      } else {
        this.multiSelectPeriodOptionsAddition[idx].hide = false;
      }
    })
  }

  updateMultiSelectOptionsSubtractionHideAttribute() {
    this.multiSelectPeriodOptionsAddition.forEach((option, idx) => {
      if (option.checked) {
        this.multiSelectPeriodOptionsSubtraction[idx].hide = true;
      } else {
        this.multiSelectPeriodOptionsSubtraction[idx].hide = false;
      }
    })
  }

  retreiveEarliestOrLatestIncludedColumnIdx(getEarliest = true) {
    let column = null;
    let colIdx = null;
    this.multiSelectPeriodOptionsAddition.forEach((option, idx) => {
      if (option.checked || this.multiSelectPeriodOptionsSubtraction[idx].checked) {
        const includedColumn = this.columns[idx];
        if (column === null ||
          (getEarliest && new Date(includedColumn.statementDate) < new Date(column.statementDate)) ||
          (!getEarliest && new Date(includedColumn.statementDate) > new Date(column.statementDate))) {
          column = includedColumn;
          colIdx = idx;
        }
      }
    })
    return colIdx;
  }

  retreiveEarliestOrLatestIncludedColumnStatementDate(getEarliest = true) {
    let column = null;
    this.multiSelectPeriodOptionsAddition.forEach((option, idx) => {
      if (option.checked || this.multiSelectPeriodOptionsSubtraction[idx].checked) {
        const includedColumn = this.columns[idx];
        if (column === null ||
          (getEarliest && new Date(includedColumn.statementDate) < new Date(column.statementDate)) ||
          (!getEarliest && new Date(includedColumn.statementDate) > new Date(column.statementDate))) {
          column = includedColumn;
        }
      }
    })
    return column !== null ? column.statementDate : null;
  }

  changeCurrency(currency: string) {
    this.currency = currency;
  }
}
