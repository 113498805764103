<div id="main">
    <div class="inner-content">
        <div class="search-fields-wrapper">
            <div class="search-input-row">
                <div class="lg-input-container input-container">
                    <label>Borrower Name</label>
                    <div class="input-group has-feedback">
                        <input type="text" class="form-control" [formControl]="borrowerNameSearchControl"
                               placeholder="Enter a value"/>
                        <ng-container *ngIf="!borrowerNameSearchControl.value; else clearIcon">
                            <span class="input-group-text">
                                <i class="fas fa-search form-control-feedback"
                                   style="color: #898989"></i>
                            </span>
                        </ng-container>
                        <ng-template #clearIcon>
                            <span class="input-group-text">
                                <i (click)="clearSearchField(borrowerNameSearchControl, 'borrowerName')"
                                   class="fas fa-times form-control-feedback clear-field-icon"
                                   style="color: #898989"></i>
                            </span>
                        </ng-template>
                    </div>
                </div>
                <div *ngIf="customerIdsUtilized" class="lg-input-container input-container">
                    <label>Customer ID</label>
                    <div class="input-group has-feedback">
                        <input type="text" class="form-control" [formControl]="customerIDSearchControl"
                               placeholder="Enter a value"/>
                        <ng-container *ngIf="!customerIDSearchControl.value; else clearIcon">
                            <span class="input-group-text">
                                <i class="fas fa-search form-control-feedback" style="color: #898989"></i>
                            </span>
                        </ng-container>
                        <ng-template #clearIcon>
                            <span class="input-group-text">
                                <i (click)="clearSearchField(customerIDSearchControl, 'customerID')"
                                   class="fas fa-times form-control-feedback clear-field-icon"
                                   style="color: #898989"></i>
                            </span>
                        </ng-template>
                    </div>
                </div>
                <div class="sm-input-container input-container">
                    <div class="has-feedback">
                        <label for="portfolioSelect">Portfolio</label>
                        <app-select2
                            id="portfolioSelect"
                            placeholder="Select"
                            [data]="portfolioOptions"
                            [options]="{minimumResultsForSearch: -1}"
                            [value]="clientSearch.portfolio"
                            (valueChanged)="filterCompanies('portfolio', $event.value)"
                            [width]="'100%'"
                        >
                        </app-select2>
                    </div>
                </div>
                <div class="sm-input-container input-container">

                    <label for="sicSelect">SIC</label>
                    <input [formControl]="sicSelectControl" list="sicSelect" class="form-control"
                           id="sicSelectInput" placeholder="Select">
                    <datalist id="sicSelect">
                        <option *ngFor="let eachSic of sicCodeOptions" [value]="eachSic.code">
                            {{eachSic.title}} - {{eachSic.code}}</option>
                    </datalist>
                </div>
                <div class="sm-input-container input-container">
                    <label for="regionSelect">Region</label>
                    <input [formControl]="regionSelectControl" list="regionSelect" class="form-control"
                           placeholder="Select">
                    <datalist id="regionSelect">
                        <option *ngFor="let region of regionOptions" [value]="region.code">{{region.title}}</option>
                    </datalist>
                </div>
                <div class="clear-btn-container input-container sm-input-container">
                    <label class="line-break-label">&nbsp;</label>
                    <button class="btn btn-link reset-btn"
                            [ngClass]="{disabled: searchIsEmpty}"
                            (click)="!searchIsEmpty && resetSearch()">
                        <i class="fa-solid fa-xmark button-icon"></i>Clear Filters
                    </button>
                </div>
            </div>
            <div class="add-borrower-btn-container">
                <label>&nbsp;</label>
                <button type="button" class="btn btn-primary form-control" (click)="addBorrower()"
                        *ngIf="showAddBorrowerButton">
                    <i class="fa-solid fa-plus button-icon"></i>Add Borrower
                </button>
            </div>
        </div>
        <div>
            <table class="table table-hover">
                <thead>
                  <tr class="sorting-header">
                      <th class="col-md-3" (click)="sortBy('name')">Name
                          <i class="fas fa-{{sort.field !== 'name' ? 'sort' : sort.dir ? 'sort-down' : 'sort-up'}}"></i>
                      </th>
                      <th class="col-md-2" *ngIf="customerIdsUtilized">{{customerIdLabel}}</th>
                      <th class="col-md-2" (click)="sortBy('portfolio.name')">Portfolio
                          <i class="fas fa-{{sort.field !== 'portfolio.name' ? 'sort' : sort.dir ? 'sort-down' : 'sort-up'}}"></i>
                      </th>
                      <th class="col-md-2">SIC</th>
                      <th class="col-md-1">Region</th>
                      <th class="col-md-1">Date Created</th>
                  </tr>
                </thead>
                <tbody *ngIf="companies.length; else noCompanies">
                  <tr *ngFor="let comp of companies | paginate: { itemsPerPage: limit, currentPage: page, totalItems: total }">
                    <td><a [routerLink]="['/companies/', comp.uuid, 'financials', 'analysis']">{{comp.name}}</a></td>
                    <td *ngIf="customerIdsUtilized">
                        {{comp.externalId}}
                    </td>
                    <td>
                        <app-portfolio-tag [company]="comp" [portfolios]="portfolios"></app-portfolio-tag>
                    </td>
                    <td>{{comp.sicCode}} {{!comp.sicCode || sicCodeDescriptionLookup[comp.sicCode] ? "-" : ""}} {{sicCodeDescriptionLookup[comp.sicCode]}}</td>
                    <td>{{comp.region || "-"}}</td>
                    <td>{{comp.createdDate | amFromUtc | amDateFormat : 'MM/DD/YYYY'}}</td>
                  </tr>
                </tbody>
                <ng-template #noCompanies>
                  <tr>
                    <td colspan="6" class="no-companies-container">
                      <div *ngIf="!companies.length && !isLoading && !searchIsEmpty">
                        <app-table-empty-state
                          [emptyStateIcon]="noCompaniesInFilteredListIcon"
                          [headerText]="noCompaniesInFilteredListHeaderText"
                          [helpText]="noCompaniesInFilteredListHelpText"
                        >
                            <ng-container *ngIf="contactSupportEmail; else defaultContactSupportMessage">
                                <p>If you think this is an error, please contact
                                    <button class="btn btn-link ps-0 pt-0 pb-1" (click)="openSupportEmail()">
                                        {{contactSupportEmail}}
                                    </button>
                                </p>
                            </ng-container>
                            <ng-template #defaultContactSupportMessage>
                                <p>If you think this is an error, please contact your administrator.</p>
                            </ng-template>
                        </app-table-empty-state>
                      </div>
                      <div *ngIf="!companies.length && !isLoading && searchIsEmpty">
                        <app-table-empty-state
                          [emptyStateIcon]="noCompaniesExistIcon"
                          [headerText]="noCompaniesExistHeaderText"
                          [helpText]="noCompaniesHelpText"
                        ></app-table-empty-state>
                      </div>
                      <div *ngIf="isLoading" class="loader" style="margin-top: 20px; margin-bottom: 20px"></div>
                    </td>
                  </tr>
                </ng-template>
            </table>
            <div *ngIf="total > limit">
                <pagination-template #p="paginationApi" (pageChange)="changePage($event)">
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" [ngClass]="{'disabled': p.isFirstPage()}">
                                <a class="page-link" (click)="!p.isFirstPage() ? p.previous() : return" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>

                            <li class="page-item" *ngFor="let page of p.pages" [ngClass]="{'active': p.getCurrent() === page.value}">
                                <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                    {{ page.label }}
                                </a>
                                <span class="page-link" *ngIf="p.getCurrent() === page.value">{{ page.label }}
                                    <span class="visually-hidden">(current)</span>
                            </span>
                            </li>

                            <li class="page-item" [ngClass]="{'disabled': p.isLastPage()}">
                                <a class="page-link" (click)="!p.isLastPage() ? p.next() : return" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </pagination-template>
            </div>
          </div>
    </div>
</div>
