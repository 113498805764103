<div
  class="table-cell"
  [ngClass]="{
    'confident': confidence > 0.5,
    'not-confident': confidence <= 0.5,
    'selected': isSelected
  }"
  (click)="clickedOnCell($event)"
  (contextmenu)="rightClicked($event)"
>
  <app-double-click-to-edit
    [sourceComments]="cellForSourceComments.sourceComments"
    [value]="value"
    (changed)="cellValueChanged.emit($event)"
    (enteredEditMode)="enteredEditMode.emit(true)"
    [editing]="isEditing"
    [isEditingDisabled]="isEditingDisabled"
    [alignRight]="isNumeric"
    [formatAsCurrency]="isNumeric"
    [hasPadding]="true"
    (valueBeforeEditing) = "valueBeforeEditsMade.emit(value)"
  ></app-double-click-to-edit>
</div>
