import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { CommonFunctions } from '../../../../utils/common-functions';
import { AlertService } from '../../../../services/alert.service';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import {
  getCellValidationFailure,
  isValidValueCell,
  isWarningCell,
  taxReturnCellFlagCopy,
  notNumberWithDecimalCommaDashRegex,
  selectionsOverlap,
  mergeSelections,
  isValidDateCell,
  CellErrorManager,
  FrontendValidationManager,
  BackendValidationManager,
  VALIDATION_STATUS,
  PageStatusStoreType,
  ERROR_STATUS, WARNING_STATUS
} from './validation';
import { UserService } from '../../../../services/user.service';
import { SharedDataService } from '../../../../services/shared-data.service';
import { EnvironmentService } from '../../../../services/environment.service';
import { StatementOption } from '../../../../models/statement-option';
import { DecimalPipe } from '@angular/common';
import { SelectedCells } from '../../../../models/review-select-cells';
import { ReviewQueueItem, ReviewQueueItemInterface } from '../../../../models/review-queue-item';
import { ReviewQueueService } from '../../../../services/review-queue.service';
import {
  DOC_PROCESSING_STATUS,
  SUPPORTED_CURRENCIES,
  DOCUMENT_FILE_TYPE,
  USER_GUIDES,
  OPTION_LABEL_CELL_STYLE,
  WARNING_CELL_STYLE,
  PROBLEM_CELL_STYLE,
  SCENARIO_TYPE_HISTORICAL,
  SCENARIO_TYPE_PROJECTION,
  STATEMENT_DATE,
  SCENARIO_TYPE,
  MANUAL_REVIEW_HEADER_ORDER,
  PREPARATION_TYPES_FOR_PROJECTIONS,
  PREPARATION_TYPES_FOR_HISTORICAL,
  PREPARATION_TYPE_TAX_RETURN_KEY,
  REPORTING_INTERVAL_ANNUAlLY_KEY,
  SUPPORTED_VIEW_SOURCE_FILE_TYPES,
  UNKNOWN_ERROR_TOAST_BODY,
  UNKNOWN_ERROR_TOAST_TITLE
} from '@utils/constants';
import { CurrencyFormattingService } from '../../../../services/currency-formatting.service';
import { DocumentFileService } from '../../../../services/document-file.service';
import { InstructionsStep } from '../../../../models/instructions-step';
import { TrackingService } from '../../../../services/tracking.service';
import { PreviousRouteService } from '../../../../services/previous-route.service';
import { LoggingService, Logger } from '../../../../services/logging.service';
import { TAX_RETURN, MANUAL_REVIEW_HEADERS, MONTH_FINAL_DAYS, LEAP_YEAR_MONTH_FINAL_DAYS } from '../../../../utils/constants'
import { UserGuideService } from '@services/user-guide.service';
import Handsontable from 'handsontable'
import { HotTableRegisterer } from '@handsontable/angular';
import { ForceReloadService } from '@services/force-reload.service';
const ValidationTooltipDelayTime = 500;
const integerRegex = /\d+/;
const INITIAL_INVALID_VALUE = 'INITIAL_INVALID_FLAG_FROM_SERVER';

@Component({
  selector: 'app-review-editor',
  templateUrl: './review-editor.component.html',
  styleUrls: ['./review-editor.component.scss'],
  providers: [DecimalPipe],
})
@AutoUnsubscribe('subsArr$')
export class ReviewEditorComponent implements OnInit, OnDestroy {
  clipboardCache: any = [];

  cellErrors: CellErrorManager = new FrontendValidationManager();
  cellErrorsFromBackendValidation: BackendValidationManager = new BackendValidationManager();
  pageStatuses: PageStatusStoreType = {};
  ERROR_STATUS = ERROR_STATUS;
  WARNING_STATUS = WARNING_STATUS;
  duplicateSelectedStatementType: 'INCOME_STATEMENT' | 'BALANCE_SHEET' | null;

  handleStatementTypeSelectorChange() {
    // clear the 'duplicate values selected' error highlighting across all pages on any change
    this.duplicateSelectedStatementType = null;
    this.model.document.pages.forEach((_, index) => this.assignPageValidationStatus(index));
  }

  assignPageValidationStatus(page) {
    this.pageStatuses[page] = this.cellErrors.getPageStatus(page);
    if (this.cellErrorsFromBackendValidation.getPageStatus(page) === ERROR_STATUS || !this._pageHasValidStatementTypeSelected(page)) {
      this.pageStatuses[page] = ERROR_STATUS;
    }
  }

  _pageHasValidStatementTypeSelected(page){
    if (this.model.document.pages[page].statementType == this.duplicateSelectedStatementType){
      return false
    }
    return ['INCOME_STATEMENT','BALANCE_SHEET','CASH_FLOW_STATEMENT'].includes(this.model.document.pages[page].statementType)
  }

  private static getPageMetadataScenarioTypes(page) {
    for (let i = 0; i < page.metadata.length; ++i) {
      if (page.metadata[i][0] === SCENARIO_TYPE) {
        return page.metadata[i];
      }
    }
    return null;
  }

  private static setPrepTypeOptionsUsingScenarioType(page, reviewQueueItem: ReviewQueueItemInterface, statementOptions: Array<Array<StatementOption>>) {
    statementOptions.forEach((statementOption, idx) => {
      if (idx > 0){ // if 0, then it's a line item row without numerical data
        if (page.metadata[MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX][idx] == SCENARIO_TYPE_HISTORICAL) { // index 2 is scenario_type
          statementOption[MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX].values = Object.keys(PREPARATION_TYPES_FOR_HISTORICAL)
        } else if (page.metadata[MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX][idx] == SCENARIO_TYPE_PROJECTION) {
          statementOption[MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX].values = Object.keys(PREPARATION_TYPES_FOR_PROJECTIONS)
        }
      }
    });
  }

  private static setMetadataScenarioTypeUsingDate(page, reviewQueueItem: ReviewQueueItemInterface) {
    if (reviewQueueItem.reviewed) {
      // We do not want to set the "Scenario Type" if the user has already been through the "Data Review" screen
      // because the user will have had the chance to "Scenario Type" so we do not want to change the user's choice.
      return;
    }

    let statementDates = page.metadata.filter(metadata => metadata[0] === STATEMENT_DATE);

    const pageScenarioTypes = ReviewEditorComponent.getPageMetadataScenarioTypes(page);

    if (statementDates.length === 0 || pageScenarioTypes === null) {
      return;
    } else {
      statementDates = statementDates[0];
    }

    for (let i = 1; i < statementDates.length; ++i) {
      if (statementDates[i] !== '') {
        if (new Date(statementDates[i]) < new Date()) {
          pageScenarioTypes[i] = SCENARIO_TYPE_HISTORICAL;
        } else {
          pageScenarioTypes[i] = SCENARIO_TYPE_PROJECTION;
        }
      }
    }
  }

  /**
   * Determines if a cell is a header cell, i.e. the cell is in the first column and in a header row.
   */
  private static cellIsAHeader(
    rowNumber: number,
    columnNumber: number,
    statementOptions: Array<Array<Array<StatementOption>>>,
    activePageNumber: number
  ): boolean {
    const headerColumnNumber = 0;
    // A new page that is created does not populate with statement options so we cannot rely on the object
    if (statementOptions[activePageNumber].length === 0) {
      return false
    }

    return rowNumber < statementOptions[activePageNumber][columnNumber]?.length && columnNumber === headerColumnNumber;
  }

  /**
   * Determines if the cell being operated on is the a preparation type cell.
   * @param page - The page that contains the rows and columns of data of interest.
   * @param columnNumber - The column number used to determine if the column is a preparation type.
   * @private
   */
  private static cellIsPreparationTypeData(page, columnNumber: number): boolean {
    return page.metadata[MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX][columnNumber] === SCENARIO_TYPE_PROJECTION;
  }

  /**
   * Determines if the cell at the position [rowNumber, columnNumber] contains a value of a header column and is in a
   * data column rather than the categorization label column.
   * @param rowNumber - The row number to be used to determine if the row is a header row.
   * @param columnNumber - The column number used to determine if the column a data column or
   * a categorization label column.
   * @private
   */
  private static cellIsInDataColumnAndIsHeaderRow(rowNumber: number, columnNumber: number): boolean {
    const categorizationLabelColumnNumber = 0;
    return rowNumber <= Object.keys(MANUAL_REVIEW_HEADER_ORDER).length - 1 &&
      columnNumber > categorizationLabelColumnNumber;
  }

  /**
   * Determines if the cell at the position [rowNumber, columnNumber] contains a value of a header column and is in the
   * scenario type header row.
   * @param rowNumber - The row number to be used to determine if this row is a header column.
   * @param columnNumber - The column number to be used to determine if this column is a data column or
   * categorization label column.
   * @private
   */
  private static cellIsInDataColumnAndIsScenarioTypeHeaderRow(rowNumber: number, columnNumber: number): boolean {
    const categorizationLabelColumnNumber = 0;
    return columnNumber > categorizationLabelColumnNumber &&
      rowNumber === MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX;
  }

  subsArr$: Subscription[] = [];
  currentScreen = 'menu';
  reviewQueueItemId = null;
  reviewQueueItem: ReviewQueueItem = null;
  hotId = 'manualReview';
  supportedCurrencies = SUPPORTED_CURRENCIES;
  model: ReviewQueueItemInterface = {
    'id': '',
    'companyId': -1,
    'taskToken': '',
    'lockKey': '',
    'status': '',
    'documentFileId': -1,
    'reviewed': false,
    'suffix': '',
    'step': '',
    'bankId': '',
    'currency': '',
    'roundedToThousands': false,
    'roundedToMillions': false,
    'document': {
      'execution_id': '',
      'pages': [],
      'fileKey': '',
      'type': DOCUMENT_FILE_TYPE.UNKNOWN
    },
    'originalFileUrl': '',
    'jobPayload': {
      'documentFile': {
        'id': '',
        'name': '',
        'reportedDate': '',
        'statementDate': '',
        'requestedPeriods': '',
        'additionalInformation': ''
      },
      'company': {
        'id': null,
        'name': null
      },
      'context': {
        'bankId': null
      },
      'original_document_name': ''
    }
  };

  error = null;
  modelLoadError = null;
  isError = false;
  canceled = false;
  activePage = 0;
  pageData: string[][] = null;
  reportingInterval;
  statementDate;
  isLoading = true;
  splitscreenOpen = false;
  roundingSelection: 'thousands' | 'millions' | 'none' = 'none';
  currency: string;
  isRoundingDropdownDisabled: boolean = false;
  modelCopy: any;
  selectedCells: SelectedCells[] = [];
  cellValidationMessage = '';
  cellValidationStyle = {};
  statementOptions: Array<Array<Array<StatementOption>>>;
  handsOnTableInstance: Handsontable = null;
  translationDict: any = {};
  decimalCounts: Array<number> = []
  numDecimalsMode = 0;

  // Data passed to hands on table for column formatting and headers
  columns: Array<Object> = [];

  // data passed to split screen component for viewing source document
  viewSource = false;
  viewSourcePage = null;
  viewSourceBox = null;

  logger: Logger;
  handsOnTableLicense = '';
  // Extended Sidebar instructions
  // eslint-disable-next-line max-len
  stepHeader = 'In this step, you will review your digitized statements to ensure that the data is correct and well-formatted. Hover your mouse over any red or yellow cells to see why there may be issues.';
  steps: InstructionsStep[] = [
    {
      title: 'Open original',
      body: 'Click \'Open original document\' next to the file name at the top of the page.',
      gifName: 'FR_1_Open_Original_Doc_updated_5_3_2021.gif'
    }, {
      title: 'Add header information',
      body: 'Reporting Interal (required)<br><br>Preparation Type (required)<br><br>Prepared By (optional)<br><br>Statement Date (required)',
      gifName: 'FR_2_add_header_information_updated_5_3_2021.gif'
    }, {
      title: 'Clean up data structure',
      body: 'Keep line item labels in the first column<br />Keep number values in the remaining columns<br />Remove columns covering unwanted periods',
      gifName: '',
    }, {
      title: 'Validate data',
      body: 'Use the “Show Source” feature to verify number values with the original document ',
      gifName: 'FR_4_validate_data_updated_5_3_2021.gif',
    }, {
      title: 'Finish',
      body: 'Click \'Next\' to move onto Categorization',
      gifName: '',
    }
  ];

  tipsBody = 'Cells that contain invalid data will be highlighted in red, you will not be able to move forward until you resolve the issue. \nOther cells that may need your attention will be highlighted in yellow, you do not need to change these cells to move forward.\nHover your mouse over the highlighted cells to see what the issues may be and how to resolve them.';

  contextMenu = {
    items: {
      'copy': {
        name: 'Copy'
      },
      'paste': {
        name: this.pasteHelperText(),
        disabled: () => (!this.clipboardCache || this.clipboardCache.length === 0),
        callback: (key, selection, clickEvent) => {
          this.handsOnTableInstance.listen();
          let plugin = this.handsOnTableInstance.getPlugin('copyPaste');
          plugin.paste(this.clipboardCache);
        }
      },
      'undo': {},
      'redo': {},
      'sanitize_cells': {
        'name': 'Remove Punctuation',
        callback: (key, selection, clickEvent) => {
          this.cleanSelectedCells();
        }
      },
      'insert_from_page': {
        name: 'Insert From',
        disabled: true,
        submenu: {
          items: []
        }
      },
      'separator1': '---------',
      'insert_rows': {
        name: 'Insert Rows',
        disabled: false,
        submenu: {
          items: [
            {
              key: 'insert_rows:insert_row_above',
              'name': 'Insert row above',
              callback: (key, selection, clickEvent) => {
                this.insertRow(selection, true);
              },
              disabled: () => {
                if (!this.handsOnTableInstance) {
                  return false;
                }
                const selectedRange = this.handsOnTableInstance.getSelected();
                if (selectedRange.length > 0) {
                  const rowNum = selectedRange[0][0];
                  if (rowNum > this.pageData.length || rowNum < this.model.document.pages[this.activePage].metadata.length) {
                    return true;
                  }
                }
                return false;
              }
            }, {
              key: 'insert_rows:insert_row_below',
              'name': 'Insert row below',
              callback: (key, selection, clickEvent) => {
                this.insertRow(selection, false);
              },
              disabled: () => {
                if (!this.handsOnTableInstance) {
                  return false;
                }
                const selectedRange = this.handsOnTableInstance.getSelected();
                if (selectedRange.length > 0) {
                  const rowNum = selectedRange[0][0];
                  if (rowNum > this.pageData.length || rowNum < this.model.document.pages[this.activePage].metadata.length) {
                    return true;
                  }
                }
                return false;
              }
            }, {
              key: 'insert_rows:insert_5_rows_above',
              'name': 'Insert 5 rows above',
              callback: (key, selection, clickEvent) => {
                for (let i = 0; i < 5; i++) {
                  this.insertRow(selection, true);
                }
              },
              disabled: () => {
                if (!this.handsOnTableInstance) {
                  return false;
                }
                const selectedRange = this.handsOnTableInstance.getSelected();
                if (selectedRange.length > 0) {
                  const rowNum = selectedRange[0][0];
                  if (rowNum > this.pageData.length || rowNum < this.model.document.pages[this.activePage].metadata.length) {
                    return true;
                  }
                }
                return false;
              }
            }, {
              key: 'insert_rows:insert_5_rows_below',
              'name': 'Insert 5 rows below',
              callback: (key, selection, clickEvent) => {
                for (let i = 0; i < 5; i++) {
                  this.insertRow(selection, false);
                }
              },
              disabled: () => {
                if (!this.handsOnTableInstance) {
                  return false;
                }
                const selectedRange = this.handsOnTableInstance.getSelected();
                if (selectedRange.length > 0) {
                  const rowNum = selectedRange[0][0];
                  if (rowNum > this.pageData.length || rowNum < this.model.document.pages[this.activePage].metadata.length) {
                    return true;
                  }
                }
                return false;
              }
            },
          ]
        }
      },
      'remove_rows': {
        'name': 'Remove rows',
        callback: (key, selection, clickEvent) => {
          this.removeRows(selection);
        },
        disabled: () => {
          if (!this.handsOnTableInstance) {
            return false;
          }
          const selectedRange = this.handsOnTableInstance.getSelected();
          if (selectedRange.length > 0) {
            const rowNum = selectedRange[0][0];
            if (rowNum > this.pageData.length || rowNum < this.model.document.pages[this.activePage].metadata.length) {
              return true;
            }
          }
          return false;
        }
      },
      'separator2': '---------',
      'insert_columns': {
        name: 'Insert Columns',
        disabled: false,
        submenu: {
          items: [
            {
              key: 'insert_columns:insert_col_left',
              'name': 'Insert column left ',
              callback: (key, selection, clickEvent) => {
                this.insertColumn(selection, true);
              },
              disabled: () => {
                if (!this.handsOnTableInstance) {
                  return false;
                }

                const firstColumnIndex = 0;
                const colIndexWhenSelectingARow = -1;
                const selectedRange = this.handsOnTableInstance.getSelected();

                if (selectedRange.length > 0) {
                  const colNum = selectedRange[0][1];
                  if (colNum === firstColumnIndex || colNum === colIndexWhenSelectingARow) {
                    return true;
                  }
                }
                return false;
              }
            }, {
              key: 'insert_columns:insert_col_right',
              'name': 'Insert column right ',
              callback: (key, selection, clickEvent) => {
                this.insertColumn(selection, false);
              }
            }, {
              key: 'insert_columns:insert_5_col_left',
              'name': 'Insert 5 columns left ',
              callback: (key, selection, clickEvent) => {
                for (let i = 0; i < 5; i++) {
                  this.insertColumn(selection, true);
                }
              },
              disabled: () => {
                if (!this.handsOnTableInstance) {
                  return false;
                }

                const firstColumnIndex = 0;
                const colIndexWhenSelectingARow = -1;
                const selectedRange = this.handsOnTableInstance.getSelected();

                if (selectedRange.length > 0) {
                  const colNum = selectedRange[0][1];
                  if (colNum === firstColumnIndex || colNum === colIndexWhenSelectingARow) {
                    return true;
                  }
                }
                return false;
              }
            }, {
              key: 'insert_columns:insert_5_col_right',
              'name': 'Insert 5 columns right ',
              callback: (key, selection, clickEvent) => {
                for (let i = 0; i < 5; i++) {
                  this.insertColumn(selection, false);
                }
              }
            },
          ]
        }
      },
      'remove_cols': {
        'name': 'Remove columns',
        callback: (key, selection, clickEvent) => {
          this.removeColumns(selection);
        },
        disabled: () => {
          if (!this.handsOnTableInstance) {
            return false;
          }
          const selectedRange = this.handsOnTableInstance.getSelected();
          if (selectedRange.length > 0) {
            const colNum = selectedRange[0][1];
            if (colNum === 0 || colNum === -1) {
              return true;
            }
          }
          return false;
        }
      },
      'set_reporting_interval_all': {
        'name': 'Set reporting interval for all columns',
        callback: (key, selection, clickEvent) => {
          const rowNum = MANUAL_REVIEW_HEADER_ORDER.REPORTING_INTERVAL_INDEX;
          const interval = this.retrieveValueFromStatementOptionsCell(rowNum, selection);
          this.autoSetStatementOptions(rowNum, interval);
        },
        hidden: () => {
          return this.shouldShowInContextMenu(MANUAL_REVIEW_HEADER_ORDER.STATEMENT_DATE_INDEX);
        }
      },
      'set_prep_type_all': {
        'name': 'Set prep type for all columns',
        callback: (key, selection, clickEvent) => {
          const rowNum = MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX;
          const prepType =  this.retrieveValueFromStatementOptionsCell(rowNum, selection);
          this.autoSetStatementOptions(rowNum, prepType);
        },
        hidden: () => {
          return this.shouldShowInContextMenu(MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX);
        }
      },
      'separator4': '---------',
    }
  };

  // Need to do this to have access to the component in the renderer.
  renderCell = this._renderCell.bind(this);

  private hotRegisterer = new HotTableRegisterer();

  constructor(
    private _apiService: ApiService,
    private _alertService: AlertService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _sharedDataService: SharedDataService,
    private _changeDetector: ChangeDetectorRef,
    private _environmentService: EnvironmentService,
    private _userService: UserService,
    private _reviewQueueService: ReviewQueueService,
    private _currencyFormattingService: CurrencyFormattingService,
    private _documentFileService: DocumentFileService,
    private _trackingService: TrackingService,
    private _loggingService: LoggingService,
    private _previousRouteService: PreviousRouteService,
    public userGuideService: UserGuideService,
    public forceReloadService: ForceReloadService,
    ) {
    this.logger = this._loggingService.rootLogger.newLogger('ReviewEditor');
  }

  ngOnInit() {
    this.handsOnTableLicense = this._environmentService.getHandsOnTableLicenseKey();
    this.setTopAndSideNavDisplay(false);
    this.userGuideService.add(USER_GUIDES.FORMATTING_DATA)
    this.userGuideService.add(USER_GUIDES.CORRECTING_DATA)
    this.userGuideService.add(USER_GUIDES.MERGING_DATA)

    this._sharedDataService.setHILExitUrl(this._previousRouteService.getPreviousUrl());

    this.subsArr$.push(this._route.parent.params.subscribe((params => {
      this.reviewQueueItemId = params['uuid'];
      if (!this._reviewQueueService.isValidUUID(this.reviewQueueItemId)) {
        this._router.navigate(['/404']);
        return;
      }

      this.retrieveReviewQueueItem();
    })));

  }

  afterTableInit = () => {
    const instance = this.hotRegisterer.getInstance(this.hotId);

    if (instance) {
      this.handsOnTableInstance = instance;
      this.setOptions(instance);
      this.autoSetStatementOptions(0);
      this.autoSetStatementOptions(1);
      this.autoSetStatementOptions(2);
      this.autoSetStatementOptions(3);
      this.autoSetStatementOptions(4);

      this.handsOnTableInstance.addHook('afterViewRender', () => {
        this.assignPageValidationStatus(this.activePage);
      });

      // @ts-ignore
      this.handsOnTableInstance.addHook('afterDocumentKeyDown', (e) => {
        if (e.key === 'm') {
          this.cleanSelectedCells();
        }
      });

      // @ts-ignore
      this.handsOnTableInstance.addHook('afterChange', (cellChange: Array<[number, number, string, string]>) => {
        // format of cellChange is [[row, prop, oldVal, newVal]]
        this.autofillMetaDataValues(cellChange);
        this.setOptions(this.handsOnTableInstance)
        this.handsOnTableInstance.render();
      });

      this.handsOnTableInstance.addHook('afterCopy', (data, coords) => {
        this.clipboardCache = data;
      });

      this.handsOnTableInstance.addHook('afterCut', (data, coords) => {
        this.clipboardCache = data;
      });
      this.validateAllPages();
    }
  }

  validateAllPages() {
    /**
     * Loop through each page and validate each cell to determine the initial state of each tab on page load. This logic
     * mirrors the validation performed in the _renderCell function, which is only executed on the active/visible tab.
     */
    this.cellErrors.reset();
    for (let pageIndex = 0; pageIndex < this.model.document.pages.length; pageIndex++) {
      const page = this.model.document.pages[pageIndex];
      for (let rowIndex = 0; rowIndex < page.cells.length; rowIndex++) {
        const row = page.cells[rowIndex];
        for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
          const cell = row[cellIndex];
          let offsetRowIndex = rowIndex;
          if (this.statementOptions[pageIndex][cellIndex]){
              offsetRowIndex = rowIndex + this.statementOptions[pageIndex][cellIndex].length;
          }
          const validationFailure = getCellValidationFailure(pageIndex, offsetRowIndex, cellIndex, cell.text,
            this.statementOptions, this._currencyFormattingService.getDecimalSeparator(this.model.currency),
            this.model.document.type, this.numDecimalsMode, this.handsOnTableInstance);
          if (!!validationFailure) {
            this.setCellStatusForValidationFailure(cell.text, pageIndex, offsetRowIndex, cellIndex, validationFailure)
          }
        }
      }
      this.assignPageValidationStatus(pageIndex);
    }
  }

  setCellStatusForValidationFailure(cell, page, row, col, validationFailure){
    let status = ERROR_STATUS
    if (isWarningCell(cell, row, col, validationFailure)){
      status = WARNING_STATUS
    }
    this.cellErrors.setCellStatus(page, row, col, status)
  }

  cleanSelectedCells(): void {
    // strip cells of non numeric chars - used to convert to whole dollars
    const selected = this.handsOnTableInstance.getSelected();
    if (selected) {
      for (let index = 0; index < selected.length; index += 1) {
        const item = selected[index];
        const startRow = Math.min(item[0], item[2]);
        const endRow = Math.max(item[0], item[2]);
        const startCol = Math.min(item[1], item[3]);
        const endCol = Math.max(item[1], item[3]);

        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex += 1) {
          for (let columnIndex = startCol; columnIndex <= endCol; columnIndex += 1) {
            const origRawValue = this.handsOnTableInstance.getDataAtCell(rowIndex, columnIndex);
            let origValue = '';
            if (origRawValue) {
              origValue = origRawValue.toString();
            }
            const cleanValue = this._reviewQueueService.cleanCellPunctuation(origValue);
            if (origValue !== cleanValue) {
              this.handsOnTableInstance.setDataAtCell(rowIndex, columnIndex, cleanValue);
            }
          }
        }
      }
      this.handsOnTableInstance.render();
    }
  }

  shouldShowInContextMenu(rowNum): boolean {
    if (!this.handsOnTableInstance) {
      return false;
    }
    const selectedRange = this.handsOnTableInstance.getSelected();

    if (selectedRange.length > 0) {
      const start_row = selectedRange[0][0];
      const end_row = selectedRange[0][2];
      if (start_row !== rowNum || end_row !== rowNum || selectedRange.length > 1) {
        return true;
      }
    }
    return false;
  }

  retrieveValueFromStatementOptionsCell(rowNum, selection): string {
    let start_col = selection[0]['start']['col'];
    if (start_col === 0) {
      start_col = 1;
    }
    return this.handsOnTableInstance.getDataAtCell(rowNum, start_col);
  }

  next(): void {
    this.save(true, true);
  }

  saveAndStay(): void {
    this.save();
  }

  handleCellClick(e: MouseEvent, coords){
    if (this.splitscreenOpen && coords['col'] >= 0 && coords['row'] >= 0) {
      this.showSource([{start: coords, end: coords}], false)
    }
  }

  showSource(selection, alertForInvalidCell: boolean = true) {
    if (selection) {
      const col = selection[0].start.col;
      const row = selection[0].start.row - this.statementOptions[this.activePage][col].length;
      const page = this.model.document.pages[this.activePage];
      if (page && page.cells[row] != null && page.cells[row][col] != null) {
        const pageNumber = page.cells[row][col].hasOwnProperty('pageNumber') ? page.cells[row][col]['pageNumber'] : -1;
        if (page.cells[row][col].hasOwnProperty('sourceBox') && page.cells[row][col]['sourceBox'] != null && this.sourceBoxIsValid(page.cells[row][col]['sourceBox'], pageNumber)) {
          // check if source box exists. if so, highlight source box
          this.openSplitscreen();
          this.viewSource = true;
          this.viewSourcePage = page.cells[row][col]['sourceBox']['pageNumber'];
          this.viewSourceBox = page.cells[row][col]['sourceBox'];
          return;
        } else if (pageNumber > -1) {
          // if no source box exists but page number has been identified bring user to page
          this.openSplitscreen();
          this.viewSource = true;
          this.viewSourcePage = page.cells[row][col]['pageNumber'];
          this.viewSourceBox = null;
          return;
        }
      }
      if (alertForInvalidCell) {
        this._alertService.warning('Could not identify source cell or source page.', 'Warning', 5);
      }
    }
  }

  sourceBoxIsValid(sourceBox, pageNumber) {
    return (
      sourceBox?.llc &&
      sourceBox?.lrc &&
      sourceBox?.ulc &&
      sourceBox?.urc &&
      sourceBox?.llc?.x &&
      sourceBox?.lrc?.x &&
      sourceBox?.ulc?.x &&
      sourceBox?.urc?.x &&
      sourceBox?.llc?.y &&
      sourceBox?.lrc?.y &&
      sourceBox?.ulc?.y &&
      sourceBox?.urc?.y &&
      // if the page number exists on the row, confirm that it matches the source cell page number
      (pageNumber === -1 || pageNumber === sourceBox?.pageNumber)
    )
  }

  insertRow(selection, insertAbove: boolean) {
    let selectedRowIndex = 0;
    if (insertAbove) {
      selectedRowIndex = selection[0].start.row;
    } else {
      selectedRowIndex = selection[0].end.row + 1;
    }
    const numCol = this.pageData[0].length;

    const arrayToInsert = []
    for (let i = 1; i <= numCol; i++) {
      arrayToInsert.push('');
    }

    this.pageData.splice(selectedRowIndex, 0, arrayToInsert);
    this.pageData = this.pageData.slice(0);

    // this.model.document.pages[this.activePage].cells.splice(selectedRowIndex - this.statementOptions.length, 0, arrayToInsert);
    const numMetadataRows = this.statementOptions[this.activePage][0].length;
    this.model.document.pages[this.activePage]
      .cells.splice(
        selectedRowIndex - numMetadataRows,
        0,
        arrayToInsert
      );
  }

  insertFrom(selection, fromPageIndex: number): void {
    const selectedRowIndex = selection[0].end.row + 1;
    let tempPageData;

    // idk why, but there's a bug where pageData first key has many extra columns
    let activePageNumCol;
    if (this.pageData.length > 1) {
      activePageNumCol = this.pageData[1].length;
    } else {
      activePageNumCol = this.pageData[0].length;
    }

    const fromPageNumCol = this.model.document.pages[fromPageIndex].cells[0].length;
    tempPageData = this.pageData;

    if (activePageNumCol < fromPageNumCol) {
      // activePage has less columns, need to add the difference to activePage and then insert stuff

      const numDifference = fromPageNumCol - activePageNumCol;

      // activePage has less columns, need to add the difference to activePage and then insert stuff
      tempPageData.forEach( (row, index) => {
        for (let i = 0; i < numDifference; i++) {
          tempPageData[index].push('');
        }
      });

      const tempCellsData = JSON.parse(JSON.stringify(this.model.document.pages[this.activePage].cells));

      this.model.document.pages[this.activePage].cells.forEach( (cell, index) => {
        for (let i = 0; i < numDifference; i++) {
          tempCellsData[index].push({ text: '', raw_text: '', translated_raw_text: '' });
        }
      });

      this.model.document.pages[this.activePage].cells = JSON.parse(JSON.stringify(tempCellsData));

      activePageNumCol = activePageNumCol + numDifference; // add in columns added to active page
    } else if (activePageNumCol > fromPageNumCol) {
      // activePage has more columns, need to add the difference to incoming data and then insert stuff
      const numDifference = activePageNumCol - fromPageNumCol;

      const tempCellsData = this.model.document.pages[fromPageIndex].cells;

      this.model.document.pages[fromPageIndex].cells.forEach( (cell, index) => {
        for (let i = 0; i < numDifference; i++) {
          tempCellsData[index].push({ text: '', raw_text: '', translated_raw_text: '' });
        }
      });

      this.model.document.pages[fromPageIndex].cells = tempCellsData;
    }

    this.insertTableData(tempPageData, fromPageIndex, activePageNumCol, selectedRowIndex);
    this.reloadTable();
    this.removeInsertedPage(fromPageIndex);
    return;
  }


  insertTableData(pageData, dataToPastePageIndex: number, activePageNumCol: number, selectedRowIndex: number): void {
    // we currently refer to this data in many locations as "pageData" or as "pages"
    // but each tab at the bottom is actually a table instead of a page
    // we will start to rename related variables as "tables" instead of "pages" as we move forward

    const tempPageData = JSON.parse(JSON.stringify(pageData));

    const tempModelCells = JSON.parse(JSON.stringify(this.model.document.pages[this.activePage].cells));

    const numMetaFields = this.model.document.pages[this.activePage].metadata.length;

    this.model.document.pages[dataToPastePageIndex].cells.forEach( (cell, index) => {

      const modelCellsKey = selectedRowIndex + index - numMetaFields;
      const pageDataKey = selectedRowIndex + index;

      tempModelCells.splice(modelCellsKey, 0, cell);

      const arrayToInsert = [];

      for (let i = 0; i < activePageNumCol; i++) {
        arrayToInsert.push(cell[i].text);
      }

      tempPageData.splice(pageDataKey, 0, arrayToInsert);
    });

    if (this.statementOptions[this.activePage].length < this.statementOptions[dataToPastePageIndex].length) {
      this.statementOptions[this.activePage].push(
          JSON.parse(JSON.stringify(this._environmentService.getStatementOptions()))
        )
    }

    this.model.document.pages[this.activePage].cells =  JSON.parse(JSON.stringify(tempModelCells));

    this.pageData = tempPageData;

  }

  removeInsertedPage(pageIndex: number): void {
    this.deletePage(pageIndex, true); // delete page w/o override confirmation
  }

  reloadTable(): void {
    this.pageData = this.pageData.slice(0);
  }

  insertColumn(selection, insertLeft) {
    // A new page that is created does not populate with statement options so we cannot rely on the object
    // Since statement options don't exist yet as the initial population is done via the add statement options button
    // we can ignore the populating in this case. All others need the statement options
    if (this.statementOptions[this.activePage].length !== 0) {
      this.statementOptions[this.activePage].push( // first we add options so we don't error later
        JSON.parse(JSON.stringify(this._environmentService.getStatementOptions()))
      );
    }

    let selectedRowIndex = 0;
    if (insertLeft) {
      selectedRowIndex = selection[0].start.col;
    } else {
      selectedRowIndex = selection[0].end.col + 1;
    }

    const grid = [];
    this.pageData.forEach(row => {
      row.splice(selectedRowIndex, 0, '')
      grid.push(row.slice(0));
    });
    this.pageData = grid;

    const cells = [];
    this.model.document.pages[this.activePage].cells.forEach(row => {
      row.splice(selectedRowIndex, 0, '');
      cells.push(row.slice(0));
    });
    this.model.document.pages[this.activePage].cells = cells;
  }

  removeRows(selection) {
    selection.sort((a, b) => (a.start.row > b.start.row) ? -1 : 1 )

    const mergedSelections = [];
    selection.forEach((s, idx: number) => {
      if (idx === 0) {
        mergedSelections.push(s);
      } else if (selectionsOverlap(mergedSelections[mergedSelections.length - 1], s)) {
        mergedSelections[mergedSelections.length - 1] = mergeSelections(mergedSelections[mergedSelections.length - 1], s);
      } else {
        mergedSelections.push(s);
      }
    });
    mergedSelections.forEach(el => {
      // If it's the same, we still want to remove 1.
      const rowsToRemove = (el.end.row - el.start.row) + 1;

      this.pageData.splice(el.start.row, rowsToRemove);
      this.pageData = this.pageData.slice(0);

      const numMetadataRows = this.statementOptions[this.activePage][0].length;

      this.model.document.pages[this.activePage].cells.splice(
        el.start.row - numMetadataRows,
        rowsToRemove
      );

      this.model.document.pages[this.activePage].cells = JSON.parse(JSON.stringify(this.model.document.pages[this.activePage].cells));
    });
  }

  removeColumns(selection) {

    selection.sort((a, b) => (a.start.col > b.start.col) ? -1 : 1 );

    const mergedSelections = [];
    selection.forEach((s, idx: number) => {
      if (idx === 0) {
        mergedSelections.push(s);
      } else if (selectionsOverlap(mergedSelections[mergedSelections.length - 1], s)) {
        mergedSelections[mergedSelections.length - 1] = mergeSelections(mergedSelections[mergedSelections.length - 1], s);
      } else {
        mergedSelections.push(s);
      }
    });

    mergedSelections.forEach(el => {

      const grid = [];
      // If it's the same, we still want to remove 1.
      const columnsToRemove = (el.end.col - el.start.col) + 1;
      this.pageData.forEach(row => {
        row.splice(el.start.col, columnsToRemove);
        grid.push(row.slice(0));
      });
      this.pageData = grid;

      const cells = [];
      this.model.document.pages[this.activePage].cells.forEach(row => {
        row.splice(el.start.col, columnsToRemove);
        cells.push(row.slice(0));
      });
      this.model.document.pages[this.activePage].cells = cells;
    });
  }

  setOptions(hotTableInstance: Handsontable): void {
    let self = this;
    hotTableInstance.updateSettings({
      afterOnCellMouseDown: (e, coords) => this.handleCellClick(e, coords),
      cells: function(row, col) {
        const cellProperties = {};

        // A new page that is created does not populate with statement options so we cannot rely on the object
        if (self.statementOptions[self.activePage].length === 0) {
          // We have a new page which contains nothing
          return cellProperties
        }

        if (row < self.statementOptions[self.activePage][col]?.length && col === 0) {
          cellProperties['readonly'] = true;
        }
        if (row < self.statementOptions[self.activePage][col]?.length && col > 0) {
          cellProperties['type'] = self.statementOptions[self.activePage][col][row].type;
          if (cellProperties['type'] === 'dropdown') {
            cellProperties['source'] = self.statementOptions[self.activePage][col][row].values;
          }
          // don't allow the type to override the custom renderer
          this.renderer = self.renderCell;
        }

        return cellProperties;
      },
      viewportRowRenderingOffset: 1,
      viewportColumnRenderingOffset: 1,
    }, false);
  }

  ngOnDestroy() {
    this.userGuideService.remove(USER_GUIDES.FORMATTING_DATA)
    this.userGuideService.remove(USER_GUIDES.CORRECTING_DATA)
    this.userGuideService.remove(USER_GUIDES.MERGING_DATA)

    this.setTopAndSideNavDisplay(true);
  }

  toggleStepsToSuccess() {
    if (this._sharedDataService.shouldShowManualReviewSidebar === true) {
      this._sharedDataService.shouldShowManualReviewSidebar = false;
    } else {
      this._sharedDataService.shouldShowManualReviewSidebar = true;
    }
  }

  setTopAndSideNavDisplay(value: boolean) {
    this._sharedDataService.shouldShowTopNav = value;
    this._sharedDataService.shouldShowSidebar = value;
  }

  retrieveReviewQueueItem(): void {
    this._apiService.send('Post', `/api/review-queue-items/${this.reviewQueueItemId}/retrieve-for-review`).toPromise().then(data => {
      this.isLoading = false;
      this.model = data.response.objects[0];
      if (this.model && this.model.embeddedWorkflow) {
        this._sharedDataService.embeddedWorkflow$.next(this.model.embeddedWorkflow);
      }

      this.model.document.pages.map(page => {
        this.addMissingMetadataToPage(page);
        this.sortPageMetadata(page);
        ReviewEditorComponent.setMetadataScenarioTypeUsingDate(page, this.model);
      });

      this.setupStatementOptions();

      // Deconstruct and make a deep copy of object so when we pass to child component it's a reference to the copy not the original
      this.modelCopy = { ...this.model };
      this.modelCopy = Object.assign({}, this.model);
      this.modelCopy = JSON.parse(JSON.stringify(this.model));

      try {
        this._trackingService.trackHumanInLoop({
          type: 'Start',
          step: 'Manual Review',
          documentFileId: this.model.document.fileKey.split('/')[0],
          documentCompanyId: this.model.jobPayload.company.id,
          documentTenantId: this.model.jobPayload.context.bankId
        });
      } catch (err) {
        console.log('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
        this.logger.error('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
      }

      if (this.model.jobPayload.documentFile.type == TAX_RETURN) {
        this.isRoundingDropdownDisabled = true;
        this.roundingSelection = "none";
      }

      if (this.model.roundedToThousands){
        this.roundingSelection = 'thousands';
      } else if (this.model.roundedToMillions){
        this.roundingSelection = 'millions';
      }

      this.currency = this.model.currency;

      this.setDefaultReportingInterval();
      this.showPage(0);
      this.addOrRemoveShowSourceFromContextMenu();
      this.addInsertIntoTableOptions();
    }).catch(err => {
      this.isLoading = false;
      this.modelLoadError = err.message;
      this.isError = true;
      // adding some console logs so we can also see error in FullStory recordings
      console.log('Error in retrieveReviewQueueItem');
      console.log(err.message);
      console.log(err);
      this.logger.error('Error in retrieveReviewQueueItem: ' + err.message, {'errorObject': err});
    });

    this.subsArr$.push(this._reviewQueueService.retrieveAndLock(this.reviewQueueItemId).subscribe((item: ReviewQueueItem) => {
      this.reviewQueueItem = item;
      if(this.reviewQueueItem.status == DOC_PROCESSING_STATUS) {
        this.forceReloadService.refreshBrowser() // something went wrong, force reload the page
        return;
      }

    }, err => {
      this._alertService.error(err);
    }));

    return;
  }

  isReportingIntervalShown(): boolean {
    if (this.model.document.pages[this.activePage].reportingInterval === 'None' ||
        this.model.document.pages[this.activePage].reportingInterval === '' ||
        this.model.document.pages[this.activePage].reportingInterval === undefined) {
      return false;
    }
    return true;
  }

  isAdditionalInformationShown(): boolean {
    if (this.model.jobPayload.documentFile.additionalInformation !== '') {
        return true;
    }
    return false;
  }

  /**
   * Determines the reporting interval by parsing the incoming reported_date from
   */
  setDefaultReportingInterval() {
    const reportedDate = this.model.jobPayload.documentFile.reportedDate;

    if (!reportedDate) {
      this.reportingInterval = 'Unknown';
    } else {
      this.reportingInterval = CommonFunctions.getPeriodTypeAsString(this.model.jobPayload.documentFile.reportedDate);
      this.setReportingIntervalAllPagesIfNull(this.reportingInterval);
    }
  }


  showScreen(screen: string): void {
    this.currentScreen = screen;
  }

  _formatDate(text) {
    const date = moment(text);
    if (date.isValid()) {
      return date.endOf('month').format('MM/DD/YYYY');
    }
    return '#ERR Invalid Date: (' + text + ')';
  }

  /**
   * Function that formats the value of a cell based on the given currency type
   *
   * Formats:
   * USD , CAD, EUR, AUD, HKD, MXN, GBP, SGD: 100,000.00
   * JPY, KRW: 100,000
   * INR: 1,00,00.00
   * RUB: 100.00,00
   * CHF: 100'000.00
   *
   * @param str
   */
  _formatNumber(str: string) {
    return this._currencyFormattingService.formatCurrency(str, this.model.currency);
  }

  changeCurrency(currency: string) {
    this.model.currency = currency;
    this.showPage(this.activePage);
  }

  /**
  * Function to set statement options.
  * rowNumber: The index in the statement options to pass to setDataAtCell in order to set the correct cell.
  * value: Value to set the content of the row at rowNumber to.
  **/
  autoSetStatementOptions(rowNumber: number, value?: string) {
    let idxOfOption;

    // If statment options don't exist for the page, there is no metadata to autoset
    if (this.statementOptions[this.activePage].length === 0) {
      return;
    }

    if (this.model.jobPayload.documentFile.type === TAX_RETURN && !value) {
      console.log('is tax return');
      if (rowNumber === MANUAL_REVIEW_HEADER_ORDER.REPORTING_INTERVAL_INDEX) {
        console.log('is MANUAL_REVIEW_HEADER_ORDER.REPORTING_INTERVAL_INDEX which is', MANUAL_REVIEW_HEADER_ORDER.REPORTING_INTERVAL_INDEX);
        // Since this is a tax return, we know that the reporting interval is a year so set the reporting interval to
        // yearly.
        // Use column one here as column one contains the default statement options.
        const columnNumber = 1;
        idxOfOption = this.statementOptions[this.activePage][columnNumber][rowNumber]
          .values.findIndex(option => option === REPORTING_INTERVAL_ANNUAlLY_KEY);
      } else if (rowNumber === MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX) {
        console.log('is MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX which is', MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX);
        // Since this is a tax return, we know that the preparation type is a tax return so set the preparation type
        // to tax return.
        // Use column zero here as column zero contains the default statement options.
        const columnNumber = 0;
        idxOfOption = this.statementOptions[this.activePage][columnNumber][rowNumber]
          .values.findIndex(option => option === PREPARATION_TYPE_TAX_RETURN_KEY);
      }
    } else if (value) {
      console.error('value: ' + value);
      // Use column one here as column one contains the default statement options.
      const columnNumber = 1;
      idxOfOption = this.statementOptions[this.activePage][columnNumber][rowNumber]
        .values.findIndex(option => option === value);
    }
    if (this.pageData.length >= 1 && this.handsOnTableInstance && idxOfOption >= 0) {
      let col = 0;
      this.pageData[rowNumber].forEach(() => {
        if (col > 0) {
          this.handsOnTableInstance.setDataAtCell(
            rowNumber,
            col,
            this.statementOptions[this.activePage][col][rowNumber].values[idxOfOption]
          );
        }
        col++;
      });
    }
  }


  /**
   * Function passed to hands on table to render the TD for each individual
   * cell. We use this to apply styling rules based on validation.
   */
  _renderCell(instance, td, row, col, prop, cell: string, cellProperties) {
    // Handle case when new row is added
    if (cell === null) {
      cell = '';
    }

    this._renderCellContent(instance, td, row, col, prop, cell, cellProperties)

    const currentBackendValidationStatus = this.cellErrorsFromBackendValidation.getCellValue(this.activePage, row, col);
    if (currentBackendValidationStatus !== undefined) {
      this._applyCellStylingForBackendValidationErrors(currentBackendValidationStatus, row, col, cell, td);
    }
    if (col === 0) {
      td.className = td.className + ' fs-exclude fs-block';
    }
    // Clear any lingering cell validation error message
    this.cellValidationMessage = '';

    const page = this.model.document.pages[this.activePage];

    const validationFailure = getCellValidationFailure(this.activePage, row, col, cell, this.statementOptions,
      this._currencyFormattingService.getDecimalSeparator(this.model.currency), this.model.document.type,
      this.numDecimalsMode, this.handsOnTableInstance);
    td.__validationFailure = validationFailure;
    let flagText = false;
    if (
      this.model.jobPayload.documentFile.type === TAX_RETURN &&
      this.statementOptions[this.activePage].length > 0 &&
      row >= this.statementOptions[this.activePage][col].length
    ) {
      if (
        page.cells[row - this.statementOptions[this.activePage][col].length] &&
        page.cells[row - this.statementOptions[this.activePage][col].length][col] &&
        page.cells[row - this.statementOptions[this.activePage][col].length][col].hasOwnProperty('flagText') &&
        page.cells[row - this.statementOptions[this.activePage][col].length][col]['flagText']
      ) {
        Object.assign(td.style, WARNING_CELL_STYLE); // if warning, color yellow
        this.cellErrors.setCellStatus(this.activePage, row, col, WARNING_STATUS);
        td.__validationFailure = taxReturnCellFlagCopy;
        flagText = true;
      } else {
        this.cellErrors.clearCellStatus(this.activePage, row, col);
      }
    }

    if (!!validationFailure || flagText) {
      if (!!validationFailure) {
        if (isWarningCell(cell, row, col, validationFailure)) {
          Object.assign(td.style,WARNING_CELL_STYLE)
          this.cellErrors.setCellStatus(this.activePage, row, col, WARNING_STATUS);
        } else {
          Object.assign(td.style, PROBLEM_CELL_STYLE); // if error, color red
          this.cellErrors.setCellStatus(this.activePage, row, col, ERROR_STATUS);
        }
      }

      if (!td.__boundEvents) {
        td.addEventListener('mouseenter', () => {
          td.__hovered = true;
          if (td.__validationFailure) {
            setTimeout(() => {
              if (td.__hovered) {
                this.showCellErrorTooltip(td);
              }
            }, ValidationTooltipDelayTime)
          }
        });
        td.addEventListener('mouseleave', () => {
          td.__hovered = false;
          if (td.__validationFailure) {
            this.hideCellErrorTooltip(td);
          }
        });

        td.__boundEvents = true;
      }
    } else {
      this.cellErrors.clearCellStatus(this.activePage, row, col);
      if (ReviewEditorComponent.cellIsAHeader(row, col, this.statementOptions, this.activePage)) {
        Object.assign(td.style, OPTION_LABEL_CELL_STYLE);
        this.cellErrors.clearCellStatus(this.activePage, row, col);
      }
    }
    return td;
  }

  _renderCellContent(instance, td, row, col, prop, cell, cellProperties){
    if (cellProperties?.type == 'dropdown') {
      const dropdownRenderer = Handsontable.renderers.getRenderer('dropdown')
      dropdownRenderer(instance, td, row, col, prop, cell, cellProperties);
    } else if (cellProperties?.type == 'text') {
      const textRenderer = Handsontable.renderers.getRenderer('text');
      textRenderer(instance, td, row, col, prop, cell, cellProperties);
    } else if (isValidValueCell(cell) && cell !== '' && cell.search(notNumberWithDecimalCommaDashRegex) === -1) {
      td.innerHTML = this._formatNumber(cell);
      td.align = 'right';
    } else {
      td.innerHTML = cell;
      td.align = 'left';
    }
  }

  _applyCellStylingForBackendValidationErrors(currentBackendValidationStatus, row, col, cell, td){
      /**
       * Cell errors originating from server-side validation need to be handled separately b/c the frontend is not aware
       * of the backend validation rules. The frontend is made aware of a backend validation failure when a submit
       * request receives an error response. We flag the problematic cell in the error manager dict by assigning a known
       * static value. On the next render of the error cell, we replace the static flag value with the initial value of
       * the cell. On all subsequent renders, we compare the current value to the initial value, and clear the error
       * state if the user has made a change to the cell. This allows the UX to reflect a user 'resolving' an  error,
       * since we are unable to reevaluate the initial validation failure on the frontend.
       */
      if (currentBackendValidationStatus === INITIAL_INVALID_VALUE){
        // initial render after receiving error response
        this.cellErrorsFromBackendValidation.setCellStatus(this.activePage, row, col, cell);
        Object.assign(td.style, PROBLEM_CELL_STYLE); // if error, color red
      } else if (currentBackendValidationStatus === cell){
        // subsequent render, cell value has not been edited
        Object.assign(td.style, PROBLEM_CELL_STYLE); // if error, color red
      } else {
        // Cell value has been changed from it's initial invalid value
        this.cellErrorsFromBackendValidation.clearCellStatus(this.activePage, row, col)
      }
  }

  /**
   * Shows the error tooltip for the cell
   */
  showCellErrorTooltip(cellTd) {
    const rect = cellTd.getBoundingClientRect();
    this.cellValidationMessage = cellTd.__validationFailure;
    const middle = rect.left + (rect.right - rect.left) / 2;
    this.cellValidationStyle = {
      'top': (rect.bottom + 5).toString() + 'px',
      'left': middle.toString() + 'px',
    }
    // Need to manually detect changes for re-rendering since it's a mouseover and not a click or some other event that angular automatically re-renders for
    this._changeDetector.detectChanges();
  }

  hideCellErrorTooltip(cellTd) {
    this.cellValidationMessage = '';
    this._changeDetector.detectChanges();
  }

  /**
   * Goes through each page and sets the reporting interval if it is not set
   */
  setReportingIntervalAllPagesIfNull(reportingInterval) {
    this.model.document.pages.forEach(page => {
      // There's a strange backend case in which the value is passed through as "None" - could be dev only but doesn't hurt to cover that here.
      if (!page.reportingInterval || page.reportingInterval === 'None') {
        page.reportingInterval = reportingInterval;
      }
    });
  }


  _setPageTabularData() {
    const page = this.model.document.pages[this.activePage];
    // this.pageData = page.cells;
    const grid = []

    if (page.hasOwnProperty('metadata')) {
      page.metadata.forEach(row => {
        const cleanRow = row.map((string) => {
          return string;
        });
        grid.push(cleanRow);
      });
    }

    page.cells.forEach(row => {
      row.forEach( (col, colIndex) => {
        if (col.rawText === 'None') {
          col.rawText = '';
        }
        if (col.text === 'None') {
          col.text = '';
        }
        if (col.translatedRawText === 'None') {
          col.translatedRawText = '';
        }

        if (colIndex !== 0 && col.text !== '') {
          this.decimalCounts.push(CommonFunctions.numDecimals(col.text));
        }

      });
      const cleanRow = row.map((cell) => {
        if (cell.translatedRawText) {
          this.translationDict[cell.text] = cell.translatedRawText;
        }

        return cell.text;
      });

      this.numDecimalsMode = CommonFunctions.mostFrequentElement(this.decimalCounts);
      grid.push(cleanRow);
    });

    this._generateColumns(grid[0].length);
    this.pageData = grid;
  }

  _generateColumns(numberOfColumns: number) {
    const columns = [{
      title: 'Line Items',
    }];

    for (let i = 1; i < numberOfColumns; i++) {
      columns.push({
        title: `Period ${i}`,
      });
    }

    this.columns = columns;
  }

  save(complete = false, continueToSpreading = false) {
    if (this.isLoading) {
      return;
    }

    this._changeDetector.detectChanges();

    this.model.document.pages.map(page => {
      page.metadata.map(metadata => {
        if (metadata[0] === SCENARIO_TYPE) {
          metadata.map(md => {
            if (md === SCENARIO_TYPE_PROJECTION) {
              console.error('PROJECTION found');
            }
          });
      }});
    });

    this._saveCurrentPage();
    if (complete) {
      this.model.status = 'complete';
      this.closeSplitscreen();
    }

    this.isLoading = true;
    this.cleanDocumentOfNones();
    this._apiService.send('Patch', `/api/review-queue-items/${this.reviewQueueItemId}`, {
      parsed_document: this.model,
      rounded_to_thousands: this.roundingSelection === "thousands",
      rounded_to_millions: this.roundingSelection === "millions",
    }).toPromise().then(data => {
      this.isLoading = false;

      if (complete) {
        this._routeToNextPage(data, continueToSpreading);
        return;
      } else {
        this._alertService.success('File saved!');
      }
    }).catch(error => {
      this.isLoading = false;
      // This will still allow raw error messages from certain django validation error to bubble up into toast messages,
      // instead of the generic 'unknown error' message. Leaving this functionality for now, since we don't have targeted
      // error handling for every possible validation failure, and the raw django message is likely to provide some
      // guidance to a user. In the future, if we have better handling of all possible validation errors, we can update
      // this logic to pull error message content into the toast message ONLY if an 'error_title' is included in the
      // error response - (we define an error_title for all known validation errors that are directly thrown in code).
      if (error && error?.message && String(error.message) !== 'undefined') {
          const title = error?.error_title ? error.error_title : UNKNOWN_ERROR_TOAST_TITLE;
          this._alertService.error(error.message, title)
      } else {
        this._alertService.error(UNKNOWN_ERROR_TOAST_BODY, UNKNOWN_ERROR_TOAST_TITLE);
      }
      if (error?.cell_validation_error_details) {
        const errorDetails = error.cell_validation_error_details;
        if (errorDetails.hasOwnProperty('page_idx') && errorDetails.hasOwnProperty('col_idx') &&
          errorDetails.hasOwnProperty('row_idx')) {
          this.highlightBackendErrorCell(errorDetails.page_idx, errorDetails.col_idx, errorDetails.row_idx);
        } else if (error.message.includes('Multiple pages are tagged as') && error.error_type === 'ValidationError' &&
          errorDetails.hasOwnProperty('duplicate_statement_type')) {
          this.highlightDuplicateStatementTypeSelectors(errorDetails.duplicate_statement_type)
        }
      }
    });
  };

  highlightBackendErrorCell(page, col, row){
    if ([page, col, row].some(val => !Number.isInteger(val))){
      // confirm that all necessary cell coordinates were provided in the api response
      return
    }
    this.cellErrorsFromBackendValidation.setCellStatus(page, row, col, INITIAL_INVALID_VALUE)
    this.assignPageValidationStatus(page);
  }

  highlightDuplicateStatementTypeSelectors(statementType){
    this.duplicateSelectedStatementType = statementType
  }

  _routeToNextPage(data: any, continueToSpreading = false) {
    const companyId: number = data && data.company_id || null;
    const statementType: string = data && data.processing_job_payload &&
      data.processing_job_payload.document_file &&
      data.processing_job_payload.document_file.type || null;

    if (continueToSpreading && companyId && statementType) {
      // Important - Spreading happens in the bank context so need to set bank id for admins
      this._userService.setBankIdContext(data.bank_id);

      const docFileUuid = data.processing_job_payload.document_file.uuid;
      try {
        this._trackingService.trackHumanInLoop({
          type: 'End',
          step: 'Manual Review',
          documentFileId: this.model.document.fileKey.split('/')[0],
          documentCompanyId: this.model.jobPayload.company.id,
          documentTenantId: this.model.jobPayload.context.bankId
        });
      } catch (err) {
        console.log('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
        this.logger.error('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
      }

      this._router.navigate(['spread', docFileUuid]);
    } else {
      this._router.navigate(['review']);
    }
  }

  _saveCurrentPage() {
    if (this.pageData == null || this.activePage == null) {
      return;
    }

    // Construct cells object with the structure of the page and new text
    const cells = [];
    const metadata = [];
    for (let i = 0; i < this.pageData.length; i++) {
      const row = this.pageData[i].map(cell => {
        return { 'text': cell, 'raw_text': cell, 'translated_raw_text':  this.translationDict[cell] || cell}
      });

      if (!MANUAL_REVIEW_HEADERS.includes(row[0].text)) { // don't push cell into main data if its a header
        cells.push(row);
      } else {
        const metadataRow = this.pageData[i].map(cell => {
          return cell;
        });
        metadata.push(metadataRow);
      }
    }

    // Loop through model and look for existing footnotes and source boxes
    // Take existing footnotes and assign to cells object which contains new page text
    for (let row = 0; row < this.model.document.pages[this.activePage].cells.length; row++) {
      for (let col = 0; col < this.model.document.pages[this.activePage].cells[row].length; col++) {
        if (cells[row] !== undefined && cells[row] !== null && cells[row][col] !== undefined && cells[row][col] !== null
          && this.model.document.pages[this.activePage].cells[row][col]) {
          cells[row][col]['footnotes'] = this.model.document.pages[this.activePage].cells[row][col].footnotes;
          cells[row][col]['sourceBox'] = this.model.document.pages[this.activePage].cells[row][col].sourceBox;
          cells[row][col]['pageNumber'] = this.model.document.pages[this.activePage].cells[row][col].pageNumber;
        }
      }
    }

    this.model.document.pages[this.activePage].cells = cells;
    this.model.document.pages[this.activePage].metadata = metadata;

    this.updateCurrencyForDocumentFile();
  }

  updateCurrencyForDocumentFile() {
    const body = {
      currency: this.model.currency
    };
    this.subsArr$.push(this._documentFileService.updateDocumentFile(this.model.jobPayload.documentFile.id, body).subscribe(response => {
      console.log(response);
    }));
  }

  addPage() {
    const newPage = {
      'statement_type': null,
      'cells': [[{'text': '', 'raw_text': ''}]],
      'workPredictors': {},
      'metadata': []
    };
    this.model.document.pages.push(newPage);
    this.statementOptions.push([]);

    this.statementOptions[this.statementOptions.length - 1][0] = JSON.parse(JSON.stringify(this._environmentService.getStatementOptions()));

    this.statementOptions[this.statementOptions.length - 1][0].forEach(option => {
        newPage.metadata.push([option.label]);
    });

    this.showPage(this.model.document.pages.length - 1, true);
    this.insertColumn([{ start: { col: 1, row: 0 }, end: { col: 1, row: this.statementOptions[this.statementOptions.length - 1][0].length + 1}}], false);

    this.addInsertIntoTableOptions();
  }

  isDocumentLocked(): boolean {
    return this._reviewQueueService.isDocumentLocked(this.model);
  }

  deletePage(pageNumber: number, overrideConfirmation = false): void {
    if (overrideConfirmation || confirm('Are you sure you want to delete this page?')) {
      this._saveCurrentPage();
      this.model.document.pages.splice(pageNumber, 1);
      this.statementOptions.splice(pageNumber, 1)

      if (this.model.document.pages.length === 0) {
        this.addPage();
      } else if (this.model.document.pages.length === this.activePage) {
        this.showPage(this.model.document.pages.length - 1, false);
      } else if (this.model.document.pages.length > 0) {
        this.showPage(this.activePage, false);
      } else {
        this.activePage = null;
      }
      this.duplicateSelectedStatementType = null;
      this.validateAllPages();
    }
    this.addInsertIntoTableOptions();
  }

  showPage(pagenum, save = true) {
    if (save) {
      this._saveCurrentPage();
    }

    this.activePage = pagenum;
    this._setPageTabularData();
    this.autoSetStatementOptions(MANUAL_REVIEW_HEADER_ORDER.STATEMENT_DATE_INDEX);
    this.autoSetStatementOptions(MANUAL_REVIEW_HEADER_ORDER.REPORTING_INTERVAL_INDEX);
    this.autoSetStatementOptions(MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX);
    this.autoSetStatementOptions(MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX);
    this.autoSetStatementOptions(MANUAL_REVIEW_HEADER_ORDER.PREPARED_BY_INDEX);

  }

  toggleSplitScreen(){
    if (this.splitscreenOpen){
      this.closeSplitscreen();
    } else {
      this.openSplitscreen();
    }
  }

  openSplitscreen() {
    this.splitscreenOpen = true;
    this.rerenderExcelTable();
  }

  closeSplitscreen() {
    this.viewSource = false;
    this.splitscreenOpen = false;
    this.rerenderExcelTable();
  }

  rerenderExcelTable(): void {
    if (this.handsOnTableInstance) {
      setTimeout(() => {
        if (this.handsOnTableInstance && !this.handsOnTableInstance.isDestroyed) {
          this.handsOnTableInstance.render();
        }
      }, 0);
    }
  }

  addOrRemoveShowSourceFromContextMenu() {
    if (SUPPORTED_VIEW_SOURCE_FILE_TYPES.includes(this.model.document.type)) {
      this.contextMenu.items['show_source'] = {
          'name': 'Show Source',
          callback: (key, selection, clickEvent) => {
            this.closeSplitscreen();
            this.showSource(selection);
        }
      }
    }
  }

  pasteHelperText(): string {
    if (CommonFunctions.isMac()) {
      return 'Paste (cmd + V)';
    } else {
      return 'Paste (ctrl + V)';
    }
  }

  addInsertIntoTableOptions(): void {
    if (this.model.document.pages.length > 0) {
      const menuItems = [];

      this.model.document.pages.forEach( (page, index) => {
        const menuItem = {
          key: `insert_from_page:${index}`,
          name: `Page ${index + 1} - ${this.model.document.pages[index].statementType}`,
          disabled: () => this.activePage === index, // don't allow inserting from current page
          callback: (key, selection, clickEvent) => {
            this.insertFrom(selection, index);
          }
        }
        menuItems.push(menuItem);
      });


      this.contextMenu.items['insert_from_page'] = {
        name: 'Insert From...',
        disabled: false,
        submenu: {
          // Custom option with submenu of items
          items: menuItems
        }

      }
    }

    return;
  }

  // updateCellStyling(cellChange: Array<[number, number, string, string]>): void {
  //   if (!cellChange) {
  //     return;
  //   }
  //
  //   const rowNumber = cellChange[0][0];
  //   const columnNumber = cellChange[0][1];
  //
  //   if (columnNumber > 0 && // column is a data column and not the categorization label column
  //     rowNumber <= Object.keys(MANUAL_REVIEW_HEADER_ORDER).length - 1 // row is a header row
  //   ) {
  //
  //     const scenarioTypeCell =
  //       this.handsOnTableInstance.getCell(MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX, columnNumber);
  //
  //     if (scenarioTypeCell.textContent.includes(SCENARIO_TYPE_PROJECTION)) {
  //       const statementDateCell =
  //         this.handsOnTableInstance.getCell(MANUAL_REVIEW_HEADER_ORDER.STATEMENT_DATE_INDEX, columnNumber);
  //       Object.assign(statementDateCell.style, ProjectionRowStyle);
  //
  //       const reportingIntervalCell =
  //         this.handsOnTableInstance.getCell(MANUAL_REVIEW_HEADER_ORDER.REPORTING_INTERVAL_INDEX, columnNumber);
  //       Object.assign(reportingIntervalCell.style, ProjectionRowStyle);
  //
  //       const scenarioTypeCell =
  //         this.handsOnTableInstance.getCell(MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX, columnNumber);
  //       Object.assign(scenarioTypeCell.style, ProjectionCellStyle);
  //
  //       const preparationTypeCell =
  //         this.handsOnTableInstance.getCell(MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX, columnNumber);
  //       Object.assign(preparationTypeCell.style, ProjectionRowStyle);
  //
  //       const preparedByCell =
  //         this.handsOnTableInstance.getCell(MANUAL_REVIEW_HEADER_ORDER.PREPARED_BY_INDEX, columnNumber);
  //       Object.assign(preparedByCell.style, ProjectionRowStyle);
  //     }
  //   }
  // }


  autofillMetaDataValues(cellChange: Array<[number, number, string, string]>): void {
    // format of cellChange is [row, prop, oldVal, newVal]
    if (!cellChange) {
      return;
    }
    const rowNum = cellChange[0][0];
    const colNum = cellChange[0][1];
    const oldValue = cellChange[0][2];
    const newValue = cellChange[0][3];
    const numCol = this.pageData[0].length;
    const numPage = this.model.document.pages.length;
    const numMetaFields = this.model.document.pages[this.activePage].metadata.length;

    let allBlankValues = true;

    if (oldValue == newValue) {
      return; // no material change so why autofill?
    }

    if (colNum > 0 && // column is a data column
      rowNum === MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX
    ) {
        this.setStatementOptionsforPrepTypeColumnFromScenarioType(this.activePage, colNum, newValue)
    }

    if (rowNum === MANUAL_REVIEW_HEADER_ORDER.STATEMENT_DATE_INDEX) {
      const inputted_statement_date = this.handsOnTableInstance.getDataAtCell(rowNum, colNum);
      if (['', null].includes(inputted_statement_date)) {
        return;
      }
      if (isValidDateCell(this.handsOnTableInstance.getDataAtCell(rowNum, colNum))
      ) {
        const now = new Date()
        this._autofillStatementDates(numCol, rowNum, allBlankValues, inputted_statement_date)

        if (new Date(inputted_statement_date) > now) {
          this.handsOnTableInstance.setDataAtCell(MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX, colNum, SCENARIO_TYPE_PROJECTION)
        } else {
          this.handsOnTableInstance.setDataAtCell(MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX, colNum, SCENARIO_TYPE_HISTORICAL)
        }

        // fill in missing statementDates on other pages in the corresponding columns
        for (let i = 0; i + this.activePage < numPage; i++) {
          const statementDate = this.model.document.pages[this.activePage + i].metadata[MANUAL_REVIEW_HEADER_ORDER.STATEMENT_DATE_INDEX][colNum];
          if (statementDate !== 'undefined' && statementDate === '' || statementDate === 'None') {
            this.model.document.pages[this.activePage + i].metadata[MANUAL_REVIEW_HEADER_ORDER.STATEMENT_DATE_INDEX][colNum] = inputted_statement_date;
            this.model.document.pages[this.activePage + i].metadata[MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX][colNum] = this.setScenarioTypeUsingDate(new Date(inputted_statement_date), now);
          }
        }
      }
      // don't want to autopopulate date so return here
      return;
    }

    // #numMetaFields - 1 is index of final meta row
    if (rowNum > (numMetaFields - 1) || colNum !== 1) {
      return; // any third+ column in prepared by row
    }

    // #numMetaFields - 1 is index of final meta row (PREPARED BY)
    if (rowNum === (numMetaFields - 1)) {
      return; // didn't implement any prepared by logic
      // that code will go here
    }

    // starts at 2 so it starts at second data column (3rd total)
    for (let columnIndex = 2; columnIndex < numCol; columnIndex++) {
      const rawVal = this.handsOnTableInstance.getDataAtCell(rowNum, columnIndex);
      let value = '';
      if (rawVal) {
        value = rawVal.toString();
      }

      if (value !== '') {
        allBlankValues = false;
      }
    }

    if (allBlankValues && rowNum !== MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX) {
      const potentialDate = this.handsOnTableInstance.getDataAtCell(rowNum - 1, colNum);

      this._setCurrentPageValuesByRow(numCol, rowNum, newValue);
      this._setAllOtherPageValuesByRow(rowNum, newValue);

      if (potentialDate && isValidDateCell(potentialDate)) {
        setTimeout(() => {

          this._autofillStatementDates(numCol, rowNum - 1, allBlankValues, potentialDate)
          const now = new Date();

          if (new Date(potentialDate) > now) {
            this.handsOnTableInstance.setDataAtCell(MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX, colNum, SCENARIO_TYPE_PROJECTION)
          } else {
            this.handsOnTableInstance.setDataAtCell(MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX, colNum, SCENARIO_TYPE_HISTORICAL)
          }

          // fill in missing statementDates on other pages in the corresponding columns
          for (let i = 0; i + this.activePage < numPage; i++) {
            const statementDate = this.model.document.pages[this.activePage + i].metadata[MANUAL_REVIEW_HEADER_ORDER.STATEMENT_DATE_INDEX][colNum];
            if (statementDate !== 'undefined' && statementDate === '' || statementDate === 'None') {
              this.model.document.pages[this.activePage + i].metadata[MANUAL_REVIEW_HEADER_ORDER.STATEMENT_DATE_INDEX][colNum] = potentialDate;
              this.model.document.pages[this.activePage + i].metadata[MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX][colNum] = this.setScenarioTypeUsingDate(new Date(potentialDate), now);
            }
          }
        }, 100);
      }
    }
  }

  cleanDocumentOfNones(): void {
    this.model.document.pages = this._documentFileService.cleanDocumentOfNones(this.model.document.pages);
  }

  private _autofillStatementDates(numCol, rowNumDate, allBlankValues, newDate) : void {
    // starts at 2 so it starts at second data column (3rd total)
    for (let columnIndex = 2; columnIndex < numCol; columnIndex++) {
      const rawValDate = this.handsOnTableInstance.getDataAtCell(rowNumDate, columnIndex);
      let value_date = '';
      if (rawValDate) {
        value_date = rawValDate.toString();
      }

      if (value_date !== '') {
        allBlankValues = false;
      }

    }

    if (allBlankValues) {
      const interval_type = this.handsOnTableInstance.getDataAtCell(MANUAL_REVIEW_HEADER_ORDER.REPORTING_INTERVAL_INDEX, 2);
      if (interval_type) {
          this. _setCurrentStatementDateValuesByRow(numCol, rowNumDate, interval_type, newDate);
      }
    }
  }

  private _setCurrentStatementDateValuesByRow(numCol: number, rowNumDate: number, interval_type: string, date: string): void {
    let currentDate = date;
    for (let columnIndex = 2; columnIndex < numCol; columnIndex++) {
      currentDate = this.updateStatementDate(currentDate, interval_type)
      this.handsOnTableInstance.setDataAtCell(rowNumDate, columnIndex, currentDate);
    }
  }

  updateStatementDate(date, interval) {
    const [month, day, year] = date.split('/').map(Number);
    let updatedDate: string;

    switch (interval) {
      case 'MONTHLY':
        updatedDate = this.calculateNewDate(month, day, year, 1)
        break;
      case 'ANNUALLY':
        updatedDate = this.calculateNewDate(month, day, year + 1, 0);
        break;
      case 'QUARTERLY':
        updatedDate = this.calculateNewDate(month, day, year, 3)
        break;
      case 'SEMI_ANNUALLY':
        updatedDate = this.calculateNewDate(month, day, year, 6)
        break;
      default:
        throw new Error('Invalid interval');
    }

    return updatedDate


  }

  isLeapYear(year) { return ((year % 4 === 0 && (year % 100 !== 0)) || year % 400 === 0) }

  calculateNewDate(month: number, day: number, year: number, incr: number) {
    const true_final_day = this.isLeapYear(year) ? LEAP_YEAR_MONTH_FINAL_DAYS : MONTH_FINAL_DAYS
    const newMonth = String((month + incr) >= 13 ? (month + incr) % 12 : (month + incr)).padStart(2, '0');
    const newMonthNoPadding = String((month + incr) >= 13 ? (month + incr) % 12 : (month + incr));
    const newDay = String(true_final_day[month] == day ? true_final_day[newMonthNoPadding] : day).padStart(2, '0');
    const newYear = String((month + incr) >= 13 ? year + 1 : year);
    return `${newMonth}/${newDay}/${newYear}`;

  }

  private _setCurrentPageValuesByRow(numCol: number, numRow: number, value: string): void {
    for (let columnIndex = 2; columnIndex < numCol; columnIndex++) {
      this.handsOnTableInstance.setDataAtCell(numRow, columnIndex, value);
    }
  }

  private _setAllOtherPageValuesByRow(numRow: number, value: string): void {
    this.model.document.pages.forEach( (page, index) => {
      if (this.activePage === index) {
        return;
      }

      const rowWithLineItemNameRemoved = [...page.metadata[numRow]];
      rowWithLineItemNameRemoved.shift();

      if (rowWithLineItemNameRemoved.length === 0) { // there's no data columns
        return;
      }

      // if a value if manually deleted by an end user, the cell value is null
      const isEmpty = !rowWithLineItemNameRemoved.some(cell => !['', null].includes(cell));

      if (isEmpty) {
        for (let columnIndex = 1; columnIndex < page.metadata[numRow].length; columnIndex++) {
          page.metadata[numRow][columnIndex] = value;
          if (numRow === MANUAL_REVIEW_HEADER_ORDER.SCENARIO_TYPE_INDEX) {
            this.setStatementOptionsforPrepTypeColumnFromScenarioType(index, columnIndex, value)
          }
        }
      }
    });
  }

  private sortPageMetadata(page) {
    // Sort the page metadata by the order in MANUAL_REVIEW_HEADERS.
    page.metadata.sort((a, b) => MANUAL_REVIEW_HEADERS.indexOf(a[0]) - MANUAL_REVIEW_HEADERS.indexOf(b[0]));
  }

  private addMissingMetadataToPage(page) {
    // Additional column headers have been added over time and need to be added to each document that does not have
    // each of the current headers.
    let numberOfColumns = 0;
    if (page.cells.length > 0) {
      numberOfColumns = page.cells[0].length;
    } else {
      return;
    }


    const scenarioTypeInMetadata = page.metadata.filter(metadata => metadata[0] === SCENARIO_TYPE);
    if (scenarioTypeInMetadata.length === 0) {
      const metadataPeriodsToAdd = [];
      for (let i = 1; i < numberOfColumns; ++i) {
        metadataPeriodsToAdd.push('');
      }
      page.metadata.push([SCENARIO_TYPE].concat(metadataPeriodsToAdd));
    }

    const statementDateInMetadata = page.metadata.filter(metadata => metadata[0] === STATEMENT_DATE);
    if (statementDateInMetadata.length === 0) {
      page.metadata.push([STATEMENT_DATE, '', '']);
    }
  }

  /**
   * Statement options need to be created and setup for each column on each page.  This is because we need to modify
   * these options for each column individually so one statement option cannot be used for all columns; otherwise,
   * changes to the statement options would be reflected across all columns instead of the column the statement options
   * are specific to.
   */
  setupStatementOptions() {
    this.statementOptions = [];
    for (let pageNumber = 0; pageNumber < this.model.document.pages.length; ++pageNumber) {

      this.statementOptions[pageNumber] = [];
      for (
        let columnNumber = 0;
        columnNumber < this.model.document.pages[pageNumber].cells[0].length;
        ++columnNumber
      ) {
        // For each column on the page, create a statementOptions.
        this.statementOptions[pageNumber].push(
          JSON.parse(JSON.stringify(this._environmentService.getStatementOptions()))
        );
      }
      ReviewEditorComponent.setMetadataScenarioTypeUsingDate(this.model.document.pages[pageNumber], this.model);
      ReviewEditorComponent.setPrepTypeOptionsUsingScenarioType(this.model.document.pages[pageNumber], this.model, this.statementOptions[pageNumber]);
    }
  }

  /**
   * This function is used to set the allowed preparation type statement options based on the scenario type.
   * Different scenarios has different allowable lists of valid preparation types, and this needs to be reflected
   *
   * @param pageNumber page on which the statement options are being changed
   * @param colNum the column for which the options are being changed
   * @param scenarioValue the scenario value for the column
   */
  private setStatementOptionsforPrepTypeColumnFromScenarioType(pageNumber, colNum, scenarioValue) {
    if (scenarioValue === SCENARIO_TYPE_PROJECTION) {
      this.statementOptions[pageNumber][colNum][MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX]
        .values.length = 0;

      Object
        .keys(PREPARATION_TYPES_FOR_PROJECTIONS)
        .map(key =>
          this.statementOptions[pageNumber][colNum][MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX]
            .values
            .push(key)
        );
    } else if (scenarioValue === SCENARIO_TYPE_HISTORICAL) {
      this.statementOptions[pageNumber][colNum][MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX]
        .values.length = 0;

      Object
        .keys(PREPARATION_TYPES_FOR_HISTORICAL)
        .map(key =>
          this.statementOptions[pageNumber][colNum][MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX]
            .values
            .push(key)
        );
    }

    // if existing prep_type does not make valid option based on scenario type, delete the cell value
    if (!this.statementOptions[pageNumber][colNum][MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX]
      .values.includes(this.handsOnTableInstance.getDataAtCell(MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX, colNum))) {
        this.handsOnTableInstance.setDataAtCell(MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX, colNum, '');
    }
  }

  private setScenarioTypeUsingDate(inputted_statement_date, current_date) {
    if (inputted_statement_date > current_date) {
      return SCENARIO_TYPE_PROJECTION;
    } else {
      return SCENARIO_TYPE_HISTORICAL;
    }
  }
}
