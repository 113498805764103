import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { DocumentFileService } from '../../../../../services/document-file.service';
import { ReviewQueueItem } from '../../../../../models/review-queue-item';
import { DocumentFile } from '../../../../../models/document-file';
import { Box } from '../models/box';
import { ReviewQueueService } from '../../../../../services/review-queue.service';
import { Table } from '../models/table';
import { AlertService } from '../../../../../services/alert.service';
import { StatusModalComponent } from '../shared/status-modal/status-modal.component';
import { NgxPopupService } from '../../../../shared/ngx-popups/ngx-popups/ngx-popups';
import { TABLE_ID_VALIDATION_STEP, USER_GUIDES } from '../../../../../utils/constants';
import { SharedDataService } from '../../../../../services/shared-data.service';
import { SUPPORTED_CURRENCIES } from '../../../../../../app/utils/constants'
import { InstructionsStep } from '../../../../../models/instructions-step';
import { TrackingService } from '../../../../../services/tracking.service';
import { PreviousRouteService } from '../../../../../services/previous-route.service';
import { LoggingService, Logger } from '../../../../../services/logging.service';
import { UserGuideService } from '@services/user-guide.service';

@Component({
  selector: 'app-review-table-identification',
  templateUrl: './review-table-identification.component.html',
  styleUrls: ['./review-table-identification.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class ReviewTableIdentificationComponent implements OnInit, OnDestroy {
  subsArr$: Subscription[] = [];
  isLoading = true;
  reviewQueueItemId = '';
  reviewQueueItem: ReviewQueueItem = null;
  documentFile: DocumentFile = null;
  tablesByPage: Array<Array<Box>> = [];

  supportedCurrencies = SUPPORTED_CURRENCIES;
  logger: Logger;
  pagesWithBoxesDrawn = [];
  areAllPagesShown = true;
  pagesToShow: Array<any> = [];

  isBoxDrawingShown = true;

  // Extended Sidebar instructions
  stepHeader = 'To digitize the statements in this document, start by drawing a box around each table with financial data you want to spread.';
  fullGif = 'Table_ID_updated_5_3_2021.gif';
  steps: InstructionsStep[] = [
    {
      title: 'Identify financials',
      body: 'Navigate pages using the preview thumbnails to find financial statement tables. Use the  toggle to see all the pages in your file or just the ones with selected tables.',
      gifName: ''
    },
    {
      title: 'Draw extraction boxes',
      body: 'Click and drag to draw a box around each table.',
      gifName: ''
    },
    {
      title: 'Finish',
      body: 'When all tables are boxed, click the \'Next\' button in the upper right.',
      gifName: ''
    }
  ]
  tipsBody = 'Include the line item names, and number values for the data you want to bring in. \n\n Ignore headers, footers and other extraneous items. \n\n You can drag to resize tables after drawing them.';
  documentIsLocked: boolean = false;

  constructor(
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _documentFileService: DocumentFileService,
    private _reviewQueueService: ReviewQueueService,
    private _router: Router,
    private _alertService: AlertService,
    private _popupService: NgxPopupService,
    private _sharedDataService: SharedDataService,
    private _previousRouteService: PreviousRouteService,
    private _trackingService: TrackingService,
    private _loggingService: LoggingService,
    public userGuideService: UserGuideService,
  ) {
    this.logger = this._loggingService.rootLogger.newLogger('UserService');
  }

  ngOnInit() {
    this.userGuideService.add(USER_GUIDES.IDENTIFYING_TABLES);

    this._sharedDataService.hideDefaultNavigation();

    this._sharedDataService.setHILExitUrl(this._previousRouteService.getPreviousUrl());
    this.subsArr$.push(this._route.parent.paramMap.subscribe((params => {
      this.reviewQueueItemId = params.get('uuid');
      if (!this._reviewQueueService.isValidUUID(this.reviewQueueItemId)) {
        this._router.navigate(['/404']);
        return;
      }
      this.loadData();
    })));
  }

  ngOnDestroy() {
    this.userGuideService.remove(USER_GUIDES.IDENTIFYING_TABLES);
    this._sharedDataService.showDefaultNavigation();
  }

  loadData() {
    this.retrieveReviewQueueItem();
  }

  next(): void {
    this.nextStep(true);
  }

  save(): void {
    this.nextStep(false);
  }

  /**
   * Converts a box used by the front-end interface to a box used by the backend doc parsing
   * @param box
   */
  toAPIBox(box: Box) {
    return {
      'ulc': {
        'x': box.x1,
        'y': box.y1,
      },
      'urc': {
        'x': box.x2,
        'y': box.y1,
      },
      'llc': {
        'x': box.x1,
        'y': box.y2,
      },
      'lrc': {
        'x': box.x2,
        'y': box.y2
      },
      'pageSize': {
        'width': box.pageWidth,
        'height': box.pageHeight,
      }
    };
  }

  showStatusPopup() {
    try {
      this._trackingService.trackHumanInLoop({
        type: 'End',
        step: 'Table Identification',
        documentFileId: this.reviewQueueItem.fileKey.split('/')[0],
        documentCompanyId: this.reviewQueueItem.processingJobPayload.company.id,
        documentTenantId: this.reviewQueueItem.processingJobPayload.context.bankId
      });
    } catch (err) {
      this.logger.error('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
    }

    this._popupService.open({
      componentType: StatusModalComponent,
      cssClass: 'modal-pipeline-processing',
      inputs: {
        reviewQueueItemId: this.reviewQueueItemId,
        closable: false,
      },
    });
  }

  /**
   * Generates a list of all the tables to send to the back end
   */
  getSaveData() {
    return this.tablesByPage.map((tablesOnPage, pageIdx) => {
      return tablesOnPage.map(table => {
        return {
          'sourcePageNumber': pageIdx,
          'boundingBox': this.toAPIBox(table),
        }
      });
    });
  }

  boxesChanged({boxes, activePageIndex}: { boxes: Array<Box>, activePageIndex: number }): void {
    this.tablesByPage[activePageIndex] = boxes;
    this.updateBoxDrawnImages();
  }

  updateBoxDrawnImages(): void {
    this.pagesWithBoxesDrawn = [];
    this.tablesByPage.forEach( (arrayOfBoxes, index) => {
      if (arrayOfBoxes.length > 0) {
        this.pagesWithBoxesDrawn.push(this.documentFile.pageImages[index]);
      }
    });
  }

  isABoxDrawn(): boolean {
    let isFound = false;
    this.tablesByPage.forEach( (arrayOfBoxes, index) => {
      if (arrayOfBoxes.length > 0) {
        isFound = true;
        return;
      }
    });
    return isFound;
  }

  togglePagesShown(): void {
    this.areAllPagesShown = !this.areAllPagesShown;
    this.setPagesToShow();
  }

  setPagesToShow(): void {
    if (this.areAllPagesShown || this.pagesWithBoxesDrawn.length === 0) {
      this.pagesToShow = this.documentFile.pageImages;
    } else {
      this.pagesToShow = this.pagesWithBoxesDrawn;
    }
  }

  nextStep(next: boolean): void {
    if (!this.isABoxDrawn()) {
      this._alertService.error('Please draw at least one box');
      return;
    }
    this.isLoading = true;

    this.subsArr$.push(this._documentFileService.updateDocumentFile(this.documentFile.id, {
      currency: this.documentFile.currency
    }).subscribe(_ => {
      this.subsArr$.push(this._apiService.send('Post', '/api/document-files/' + this.documentFile.id + '/hil-data', {
        'hil_data': this.getSaveData(),
        'next': next,
        'lock_key': this.reviewQueueItem.lockKey,
        'current_step': TABLE_ID_VALIDATION_STEP,
      }).subscribe(data => {
        this.isLoading = false;
        if (next) {
          this.showStatusPopup();
        } else {
          this._alertService.success('Data saved.');
        }
      }, err => {
        this._alertService.error(err);
      }));
    }));
  }

  loadTables(): void {
    this.subsArr$.push(this._documentFileService.loadTables(this.documentFile.id, true).subscribe((tables: Array<Table>) => {
      tables.forEach(table => {
        if (table.boundingBox !== null) { // if null, don't insert because this breaks the box drawing mechanisms
          if (this.tablesByPage[table.sourcePageNumber]) {
            this.tablesByPage[table.sourcePageNumber].push(table.boundingBox);
          }
        }
      });

      this.isLoading = false;

      this.updateBoxDrawnImages();
      this.setPagesToShow();
    }, err => {
      this._alertService.error(err);
    }));
  }

  changeCurrency(currency): void {
    this.documentFile.currency = currency;
  }

  toggleStepsToSuccess() {
    if (this._sharedDataService.shouldShowManualReviewSidebar === true) {
      this._sharedDataService.shouldShowManualReviewSidebar = false;
    } else {
      this._sharedDataService.shouldShowManualReviewSidebar = true;
    }
  }

  retrieveReviewQueueItem(): void {
    this.subsArr$.push(this._reviewQueueService.retrieveAndLock(this.reviewQueueItemId).subscribe((item: ReviewQueueItem) => {
      this.reviewQueueItem = item;
      this.documentIsLocked = this._reviewQueueService.isDocumentLocked(item);
      if (this.reviewQueueItem && this.reviewQueueItem.embeddedWorkflow) {
        this._sharedDataService.embeddedWorkflow$.next(this.reviewQueueItem.embeddedWorkflow);
      }
      this.retrieveDocumentFile();
      try {
        this._trackingService.trackHumanInLoop({
          type: 'Start',
          step: 'Table Identification',
          documentFileId: this.reviewQueueItem.fileKey.split('/')[0],
          documentCompanyId: this.reviewQueueItem.processingJobPayload.company.id,
          documentTenantId: this.reviewQueueItem.processingJobPayload.context.bankId
        });
      } catch (err) {
        this.logger.error('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
      }

    }, err => {
      this._alertService.error(err);
    }));
  }

  toggleBoxDrawing(): void {
    this.isBoxDrawingShown = !this.isBoxDrawingShown;
  }

  private retrieveDocumentFile(): void {
    // Set the bank ID to the one from the queue item
    this._apiService.setTenantName(this.reviewQueueItem.bankId);
    this.subsArr$.push(this._documentFileService.listDocumentFiles(null, this.reviewQueueItem.documentFileId, true).subscribe(docs => {
      this.documentFile = docs[0];
      this.documentFile.pageImages.forEach((pi, idx) => {
        this.tablesByPage[idx] = [];
      });
      this.loadTables();
    }, err => {
      alert(err);
    }));
  }
}
