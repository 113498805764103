import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Cell } from '@components/main/spreading/models/models';

@Component({
  selector: 'app-statement-table-cell',
  templateUrl: './statement-table-cell.component.html',
  styleUrls: ['./statement-table-cell.component.scss']
})
export class StatementTableCellComponent implements OnInit {
  @Input() value: string;
  @Input() isEditingDisabled: boolean = false;
  @Output() cellSelected = new EventEmitter<any>();
  @Output() enteredEditMode = new EventEmitter<any>();
  @Output() cellValueChanged = new EventEmitter<string>();
  @Output() rightClickedOnCell = new EventEmitter<MouseEvent>();
  @Output() valueBeforeEditsMade = new EventEmitter<string>();
  @Input() confidence: number;
  @Input() isEditing = false;
  @Input() isSelected = false;
  @Input() isNumeric = false;
  @Input() cellForSourceComments: Cell;

  // Emits when the interface would have changed so if there's any parent component that is fully
  // controlling the render cycle it can know to re-render itself.
  @Output() rerenderEvent = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  clickedOnCell(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.cellSelected.emit();
  }

  rightClicked(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    this.rightClickedOnCell.emit(evt);
  }
}
