import { Component, OnInit, OnDestroy, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { ReviewQueueItem } from '../../../../../models/review-queue-item';
import { ApiService } from '../../../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentFileService } from '../../../../../services/document-file.service';
import { DocumentFile } from '../../../../../models/document-file';
import { Table } from '../models/table';
import { ReviewQueueService } from '../../../../../services/review-queue.service';
import { AlertService } from '../../../../../services/alert.service';
import { NgxPopupService } from '../../../../shared/ngx-popups/ngx-popups/ngx-popups';
import { StatusModalComponent } from '../shared/status-modal/status-modal.component';
import {
  TABLE_TABULATION_VALIDATION_STEP,
  USER_GUIDES,
  DOC_PROCESSING_STATUS,
  SUPPORTED_CURRENCIES
} from '../../../../../utils/constants';
import { SharedDataService } from '../../../../../services/shared-data.service';
import { InstructionsStep } from '../../../../../models/instructions-step';
import { TrackingService } from '../../../../../services/tracking.service';
import { PreviousRouteService } from '../../../../../services/previous-route.service';
import { LoggingService, Logger } from '../../../../../services/logging.service';
import { UserGuideService } from '@services/user-guide.service';
import { ForceReloadService } from '@services/force-reload.service';

@Component({
  selector: 'app-review-table-tabulation',
  templateUrl: './review-table-tabulation.component.html',
  styleUrls: ['./review-table-tabulation.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class ReviewTableTabulationComponent implements OnInit, OnDestroy {

  @ViewChildren('thumbnailItem') thumbnailItems: QueryList<ElementRef>;


  subsArr$: Subscription[] = [];
  isLoading = true;
  reviewQueueItemId = '';

  reviewQueueItem: ReviewQueueItem = null;
  isReviewQueueItemLocked: boolean = false;
  documentFile: DocumentFile = null;

  tablesByPage: Array<Array<Table>> = [];
  supportedCurrencies = SUPPORTED_CURRENCIES;
  boxesByPage: Array<Array<any>> = [];

  activeTableIndex = 0;
  activePageIndex = 0;

  pagesWithBoxesDrawn = [];
  areAllPagesShown = false;
  pagesToShow: Array<any> = [];

  // Extended Sidebar instructions
  stepHeader = 'With statements selected, you can now confirm that our system has correctly identified the columns within the tables. To the right, you will see a preview of the digitized column structure.';
  fullGif = 'Column_Adj_updated_5_3_2021.gif';
  steps: InstructionsStep[] = [
    {
      title: 'Verify data output',
      body: 'Check the data preview on the right to see that it matches the expected column structure in each table.',
      gifName: ''
    },
    {
      title: 'Adjust columns (if needed)',
      body: 'Redraw boxes to merge any split columns and update your preview of the digitized table by clicking ‘Save Progress’.',
      gifName: ''
    },
    {
      title: 'Review other tables',
      body: 'Navigate to other tables in the thumbnail viewer on the left and repeat the above steps.',
      gifName: ''
    },
    {
      title: 'Finish',
      body: 'Click \'Next\' when done.',
      gifName: ''
    }
  ]
  tipsBody = 'You will have the chance to remove extra columns in the next step.';
  logger: Logger;

  constructor(
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _documentFileService: DocumentFileService,
    private _reviewQueueService: ReviewQueueService,
    private _alertService: AlertService,
    private _popupService: NgxPopupService,
    private _sharedDataService: SharedDataService,
    private _previousRouteService: PreviousRouteService,
    private _trackingService: TrackingService,
    public loggingService: LoggingService,
    public userGuideService: UserGuideService,
    public forceReloadService: ForceReloadService,
  ) {
    this.logger = this.loggingService.rootLogger.newLogger('ReviewTableTabulationComponent');
  }

  ngOnInit() {
    this.userGuideService.add(USER_GUIDES.ADJUSTING_COLUMNS)
    this._sharedDataService.hideDefaultNavigation();

    this._sharedDataService.setHILExitUrl(this._previousRouteService.getPreviousUrl());

    this.subsArr$.push(this._route.parent.paramMap.subscribe((params => {
      this.reviewQueueItemId = params.get('uuid');
      if (!this._reviewQueueService.isValidUUID(this.reviewQueueItemId)) {
        this._router.navigate(['/404']);
        return;
      }

      this.loadData();
    })));
  }

  ngOnDestroy() {
    this.userGuideService.remove(USER_GUIDES.ADJUSTING_COLUMNS)
    this._sharedDataService.showDefaultNavigation();
  }

  next(): void {
    this.nextStep(true);
  }

  save(): void {
    this.nextStep(false);
  }

  getSaveData() {
    return [].concat.apply([], this.tablesByPage.map(page => {
      return page.map(table => {
        return {
          'columnStructure': table.columnStructure.map(b => b.serialize())
        };
      });
    }));
  }

  nextStep(next: boolean) {
    this.isLoading = true;

    this.subsArr$.push(this._documentFileService.updateDocumentFile(this.documentFile.id, {
      currency: this.documentFile.currency
    }).subscribe(_ => {
      this.subsArr$.push(this._apiService.send('Post', '/api/document-files/' + this.documentFile.id + '/hil-data', {
        'hil_data': this.getSaveData(),
        'next': next,
        'lock_key': this.reviewQueueItem.lockKey,
        'current_step': TABLE_TABULATION_VALIDATION_STEP,
      }).subscribe(data => {
        this.isLoading = false;
        if (!next) {
          this.setTablesFromData(data.response.objects.map(o => new Table().deserialize(o)), true);
          this._alertService.success('Data saved and retabulated');
        } else {
          this.showStatusPopup();
        }
      }, err => {
        this._alertService.error(err);
      }));
    }));
  }

  showStatusPopup() {
    try {
      this._trackingService.trackHumanInLoop({
        type: 'End',
        step: 'Column Identification',
        documentFileId: this.reviewQueueItem.fileKey.split('/')[0],
        documentCompanyId: this.reviewQueueItem.processingJobPayload.company.id,
        documentTenantId: this.reviewQueueItem.processingJobPayload.context.bankId
      });
    } catch (err) {
      this.logger.error('Error in tracking human-in-loop event: ' + err.message, { 'errorObject': err });
    }

    this._popupService.open({
      componentType: StatusModalComponent,
      cssClass: 'modal-pipeline-processing',
      inputs: {
        reviewQueueItemId: this.reviewQueueItemId,
        closable: false,
      },
    });
  }

  boxClicked(boxIdx: number, pageIdx: number) {
    this.activePageIndex = pageIdx;
    this.activeTableIndex = boxIdx;
  }


  loadData() {
    this.retrieveReviewQueueItem();
  }

  activatePage(event, pageIndex: number) {
    if (event) {
      event.preventDefault();
    }

    this.activePageIndex = pageIndex;
    if (!this.tablesByPage[pageIndex]) {
      this.tablesByPage[pageIndex] = [];
    }
    const thumbnailIndex = this.mapPageIndexToThumbnailIndex(pageIndex);
    if (this.thumbnailItems) {
      this.scrollToThumbnail(thumbnailIndex)
    }
    else {
      console.error('thumbnailItems still undefined');
    }
  }

  setTablesFromData(tables: Array<Table>, onSave: boolean = false) {
    this.tablesByPage = this.documentFile.pageImages.map(pi => []);
    tables.forEach(table => {
      // Set the proportions correctly - might have to wait until we render the image before we can draw it? yeah probably.

      this.tablesByPage[table.sourcePageNumber].push(table);
    });
    this.updateBoxDrawnImages();
    //added on Save to minumize use of currentTableWithData
    //because not sure of all the use cases for firstTableWithData 
    if (onSave) {
      this.activatePage(null, this.currentTableWithData())
    }
    else {
      this.activatePage(null, this.firstTableWithData());
    }
  }

  loadTables() {
    this.subsArr$.push(this._documentFileService.loadTables(this.documentFile.id, false).subscribe((tables: Array<Table>) => {
      this.setTablesFromData(tables);
      this.isLoading = false;
    }, err => {
      this._alertService.error(err);
    }));
  }

  toggleStepsToSuccess() {
    if (this._sharedDataService.shouldShowManualReviewSidebar === true) {
      this._sharedDataService.shouldShowManualReviewSidebar = false;
    } else {
      this._sharedDataService.shouldShowManualReviewSidebar = true;
    }
  }

  updateBoxDrawnImages(): void {
    // might need to make sure we don't have glitches
    this.pagesWithBoxesDrawn = [];
    this.boxesByPage = [];
    this.tablesByPage.forEach( (arrayOfBoxes, index) => {
      if (arrayOfBoxes.length > 0) {
        this.pagesWithBoxesDrawn.push(this.documentFile.pageImages[index]);
        this.boxesByPage.push(arrayOfBoxes.map(t => t.boundingBox))
      } else {
        this.boxesByPage.push([]);
      }
    });
    this.setPagesToShow();
  }

  togglePagesShown(): void {
    this.areAllPagesShown = !this.areAllPagesShown;
    this.setPagesToShow();
  }

  changeCurrency(currency): void {
    this.documentFile.currency = currency;
  }

  setPagesToShow(): void {
    if (this.pagesWithBoxesDrawn.length === 0) {
      this.areAllPagesShown = true;
    }
    if (this.areAllPagesShown) {
      this.pagesToShow = this.documentFile.pageImages;
    } else {
      this.pagesToShow = this.pagesWithBoxesDrawn;
    }
  }

  firstTableWithData(): number {
    const tableIndex = this.tablesByPage.findIndex(page => page.length > 0);

    if (tableIndex === -1) {
      return 0;
    }
    return tableIndex
  }

  currentTableWithData(): number {
    // Check if the current page at activePageIndex has any tables

    if (this.tablesByPage[this.activePageIndex]?.length > 0) {
      return this.activePageIndex;
    }

    // If the current activePageIndex doesn't have tables (ie they were deleted), find the first page that does
    return this.firstTableWithData()

  }


  scrollToThumbnail(idx: number = 0) {

    // observable, waiting for changes
    this.thumbnailItems.changes.subscribe(() => {


      if (this.thumbnailItems.length === 0) {
        return;
      }

      const thumbnailElement = this.thumbnailItems.toArray()[idx]?.nativeElement;

      this.scrollToRef(thumbnailElement)

    });

  }

  scrollToRef(ref:any){
    if(ref){
      ref.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
    else {
      console.error(`Cannot scroll to`);
    }
  }


  //The indexing of thumbnail is not straightforward when toggling view all pages
  //added this to match the index despite this.areAllPagesShown true or false
  mapPageIndexToThumbnailIndex(pageIndex: number): number {
    // Find the index of the current page in the visible pages array 
    const thumbnailIndex = this.pagesToShow.findIndex(page => page.pageIndex === pageIndex);
    return thumbnailIndex !== -1 ? thumbnailIndex : 0;
  }


  retrieveReviewQueueItem() {
    this.subsArr$.push(this._reviewQueueService.retrieveAndLock(this.reviewQueueItemId).subscribe((reviewQueueItem: ReviewQueueItem) => {
      this.reviewQueueItem = reviewQueueItem;
      this.isReviewQueueItemLocked = this._reviewQueueService.isDocumentLocked(reviewQueueItem);
      if (this.reviewQueueItem && this.reviewQueueItem.embeddedWorkflow) {
        this._sharedDataService.embeddedWorkflow$.next(this.reviewQueueItem.embeddedWorkflow);
      }

      if(reviewQueueItem.status == DOC_PROCESSING_STATUS) {
        this.forceReloadService.refreshBrowser() // something went wrong, force reload the page
        return;
      }

      this.retrieveDocumentFile();

      try {
        this._trackingService.trackHumanInLoop({
          type: 'Start',
          step: 'Column Identification',
          documentFileId: this.reviewQueueItem.fileKey.split('/')[0],
          documentCompanyId: this.reviewQueueItem.processingJobPayload.company.id,
          documentTenantId: this.reviewQueueItem.processingJobPayload.context.bankId
        });
      } catch (err) {
        this.logger.error('Error in tracking human-in-loop event: ' + err.message, { 'errorObject': err });
      }

    }, err => {
      this._alertService.error(err);
    }));
  }

  private retrieveDocumentFile() {
    // Set the bank ID to the one from the queue item
    this._apiService.setTenantName(this.reviewQueueItem.bankId);
    this.subsArr$.push(this._documentFileService.listDocumentFiles(null, this.reviewQueueItem.documentFileId, true).subscribe(docs => {
      this.documentFile = docs[0];
      this.loadTables();
    }, err => {
      this._alertService.error(err);
    }));
  }
}
